/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useRef, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Form, Select } from 'antd';
import { EnrollmentGuideContext } from '../../index';
import ProgressBar from '../ProgressBar';

const DeviceOwner = props => {
  const guideDetails = useContext(EnrollmentGuideContext);
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [deviceOwner, setDeviceOwner] = useState(guideDetails.data.deviceOwner);

  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState('deviceOwner');

  const updateOwner = value => {
    setDeviceOwner(value);
  };

  const onFinish = values => {
    let obj = {};
    obj.title = t('label_enrollment_guide_owner');
    obj.key = 'ownerType';
    obj.value = deviceOwner;
    props.callback(obj, pageIndex);
  };

  return (
    <div>
      <Form
        name="ownerType"
        onFinish={e => onFinish(e)}
        ref={formRef}
        initialValues={{ ownerType: deviceOwner }}
      >
        <Form.Item
          name="ownerType"
          label={t('label_enrollment_guide_owner')}
          rules={[
            {
              required: true,
              message: t('form_fieldRequiredMsg'),
            },
          ]}
        >
          <Select onChange={e => updateOwner(e)}>
            <Select.Option
              key="employee"
              value={t('label_enrollment_guide_employee')}
            >
              {t('label_enrollment_guide_employee')}
            </Select.Option>
            <Select.Option
              key="employer"
              value={t('label_enrollment_guide_employer')}
            >
              {t('label_enrollment_guide_employer')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            {t('label_next')}
          </Button>
        </Form.Item>
      </Form>
      <ProgressBar progress={0} />
    </div>
  );
};

export default withTranslation()(DeviceOwner);
