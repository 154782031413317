/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLeaflet } from 'react-leaflet';
import L from 'leaflet';
import greenMarker from '../../../../../../../public/images/marker-icon-green.png';
import redMarker from '../../../../../../../public/images/marker-icon-red.png';

const AnimatePath = ({
  startAnimation,
  resetAnimation,
  locationHistorySnapshots,
  speed,
  pause,
  restartAnimationCallback,
  startAnimationCallback,
}) => {
  const { map } = useLeaflet();

  if (resetAnimation) {
    map.resetMap();
  }

  // eslint-disable-next-line no-undef
  L.Polyline.include({
    timestamp: 0,
    rings: 0,
    vertices: 0,
    distance: 0,
    animatePath: false,

    animateIn: function() {
      if (this.animatePath) {
        return;
      }

      if (
        !('performance' in window) ||
        !('now' in window.performance) ||
        !this._map
      ) {
        return;
      }

      this.animatePath = true;
      this.animateTime = performance.now();
      this.vertices = this.rings = this.distance = 0;

      if (!this.animateLatLangs) {
        this.animateLatLangs = L.LineUtil.isFlat(this._latlngs)
          ? [this._latlngs]
          : this._latlngs;
      }

      this._latlngs = [
        [this.animateLatLangs[0][0], this.animateLatLangs[0][0]],
      ];

      this._update();
      this.animate();
      this.fire('animatestart');
      return this;
    },

    animate: function() {
      const now = performance.now();
      const diff = now - this.animateTime;
      const forward = (diff * this.options.snakingSpeed) / 1000;
      this.animateTime = now;

      this._latlngs[this.rings].pop();

      return this.animateForward(forward);
    },

    animateForward: function(forward) {
      if (!this._map) {
        return;
      }
      const currPoint = this._map.latLngToContainerPoint(
        this.animateLatLangs[this.rings][this.vertices],
      );
      const nextPoint = this._map.latLngToContainerPoint(
        this.animateLatLangs[this.rings][this.vertices + 1],
      );

      const distance = currPoint.distanceTo(nextPoint);

      if (this.distance + forward > distance) {
        this.vertices++;
        this._latlngs[this.rings].push(
          this.animateLatLangs[this.rings][this.vertices],
        );

        if (this.vertices >= this.animateLatLangs[this.rings].length - 1) {
          if (this.rings >= this.animateLatLangs.length - 1) {
            return this._snakeEnd();
          }
          this.vertices = 0;
          this.rings++;
          this._latlngs[this.rings] = [
            this.animateLatLangs[this.rings][this.vertices],
          ];
        }

        this.distance -= distance;
        return this.animateForward(forward);
      }

      this.distance += forward;
      const percent = this.distance / distance;
      const headPoint = nextPoint
        .multiplyBy(percent)
        .add(currPoint.multiplyBy(1 - percent));

      if (speed !== 0) {
        const headLatLng = this._map.containerPointToLatLng(headPoint);
        this._latlngs[this.rings].push(headLatLng);

        this.setLatLngs(this._latlngs);
      }
      this.fire('animate');
      L.Util.requestAnimFrame(this.animate, this);
    },

    _snakeEnd: function() {
      this.setLatLngs(this.animateLatLangs);
      this.animatePath = false;
      this.fire('animateend');
    },
  });

  L.Polyline.mergeOptions({
    snakingSpeed: speed * 1000,
  });

  L.LayerGroup.include({
    _snakingLayers: [],
    _snakingLayersDone: 0,

    animateIn: function() {
      if (
        !('performance' in window) ||
        !('now' in window.performance) ||
        !this._map ||
        this.animatePath
      ) {
        return;
      }

      this.animatePath = true;
      this._snakingLayers = [];
      this._snakingLayersDone = 0;
      const keys = Object.keys(this._layers);
      for (let i in keys) {
        const key = keys[i];
        this._snakingLayers.push(this._layers[key]);
      }
      this.clearLayers();

      this.fire('animatestart');
      startAnimationCallback();
      return this._snakeNext();
    },

    _snakeNext: function() {
      if (this._snakingLayersDone >= this._snakingLayers.length) {
        this.fire('animateend');
        restartAnimationCallback();
        return;
      }

      const currentLayer = this._snakingLayers[this._snakingLayersDone];

      this._snakingLayersDone++;

      this.addLayer(currentLayer);

      if (resetAnimation) {
        this.clearLayers();
      }
      if ('animateIn' in currentLayer) {
        currentLayer.once(
          'animateend',
          function() {
            setTimeout(this._snakeNext.bind(this), this.options.snakingPause);
          },
          this,
        );
        currentLayer.animateIn();
      } else {
        setTimeout(this._snakeNext.bind(this), this.options.snakingPause);
      }

      this.fire('animate');
      return this;
    },
  });

  L.LayerGroup.mergeOptions({
    snakingPause: 200 - pause * 20,
  });

  L.Map.include({
    resetMap: function() {
      this._resetMap();
    },

    _resetMap: function() {
      this.eachLayer(function(layer) {
        if (layer.animatePath === true) {
          this.removeLayer(layer);
        }
      }, this);
    },
  });

  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: greenMarker,
    shadowUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png',
  });

  const redIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: redMarker,
    shadowUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png',
  });

  useEffect(() => {
    if (!startAnimation) {
      return;
    }

    let path = [];

    if (locationHistorySnapshots.length > 0) {
      let i;
      for (i = 0; i < locationHistorySnapshots.length - 1; i++) {
        if (i === 0) {
          path.push(
            L.marker(locationHistorySnapshots[i], { icon }),
            L.polyline([
              locationHistorySnapshots[i],
              locationHistorySnapshots[i + 1],
            ]),
          );
        } else if (i === locationHistorySnapshots.length - 2) {
          path.push(
            L.marker(locationHistorySnapshots[i], { redIcon }),
            L.polyline([
              locationHistorySnapshots[i],
              locationHistorySnapshots[i + 1],
            ]),
          );
        } else {
          path.push(
            L.polyline([
              locationHistorySnapshots[i],
              locationHistorySnapshots[i + 1],
            ]),
          );
        }
      }
    }

    const route = L.featureGroup(path);

    map.fitBounds(route.getBounds());

    map.addLayer(route);

    route.animateIn();
  }, [startAnimation, speed, resetAnimation]);

  return null;
};

AnimatePath.propTypes = {
  startAnimation: PropTypes.bool.isRequired,
};

export default AnimatePath;
