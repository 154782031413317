/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, notification } from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import UserForm from '../UserForm';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
      roles: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-user');
    if (JSON.parse(name)) {
      this.setState({
        isModalVisible: true,
      });
    }
  };

  addUser = values => {
    this.onConfirmAddUser(values);
  };

  onConfirmAddUser = value => {
    this.setState({ loading: true });
    const { t } = this.props;
    const userData = {
      username: value.userStoreDomain + '/' + value.username,
      firstname: value.firstname,
      lastname: value.lastname,
      emailAddress: value.emailAddress,
      roles: value.userRoles,
      password: value.password,
    };
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/users',
        userData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.props.fetchUsers();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updateUserMsg'),
          });
          this.setState({
            isModalVisible: false,
            loading: false,
          });
          window.history.pushState(null, '', location.href.split('?')[0]);
        }
      })
      .catch(error => {
        handleApiError(error, null, t, `${this.constructor.name}AddUser`);
        this.setState({ loading: false });
      });
  };

  onCancelHandler = e => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={t('title_createNewUser')}
        open={this.state.isModalVisible}
        onCancel={this.onCancelHandler}
        footer={null}
        maskClosable={false}
      >
        <HtmlComments permission={'/permission/admin/device-mgt/roles/view'} />
        <UserForm
          action={'Add'}
          modalVisibility={this.state.isModalVisible}
          callback={values => this.addUser(values)}
        />
      </Modal>
    );
  }
}

export default withConfigContext(withTranslation()(AddUser));
