/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { Component } from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { PageHeader, Result, Typography } from 'antd';
import DuplicatePolicy from './components/DuplicatePolicy';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import { itemRender } from '../../../../../../services/utils/pageHandler';
import Authorized from '../../../../../../components/Authorized';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/policies`,
      breadcrumbName: t('home_menu_policies'),
    },
    {
      breadcrumbName: t('label_duplicatePolicy'),
    },
  ];
};

class DuplicateSelectedPolicy extends Component {
  routes;

  constructor(props) {
    super(props);
    this.routes = props.routes;
    this.config = this.props.context;
    this.state = {
      data: {},
      policyOverview: {},
      policyId: '',
      deviceType: '',
      policyName: '',
    };
  }

  setPolicyData = (type, name) => {
    let deviceType = this.config.deviceTypes.find(
      deviceTypes => deviceTypes.toLowerCase() === type,
    );
    deviceType =
      deviceType.toUpperCase() === deviceType
        ? deviceType.charAt(0) + deviceType.slice(1).toLowerCase()
        : deviceType;
    const policyName = name.charAt(0).toUpperCase() + name.slice(1);
    this.setState({
      deviceType,
      policyName,
    });
  };

  render() {
    const {
      match: { params },
      t,
    } = this.props;
    const { policyName, deviceType } = this.state;
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/policies/manage'}
        />
        <Authorized
          scope={['perm:policies:update']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <FileAddOutlined />
                    </span>
                    {t('duplicatePolicy_headerTxt', {
                      deviceType: t(`label_${deviceType.toLowerCase()}`),
                      policyName: policyName,
                    })}
                  </Title>
                }
                ghost={false}
                breadcrumb={{
                  routes: routes(this.config.appName, t),
                  itemRender: itemRender,
                }}
              />
              <div className={styles.container}>
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/platform-configurations/view OR\n' +
                    '/permission/admin/device-mgt/devices/enroll/android'
                  }
                />
                <DuplicatePolicy
                  policyId={params.policyId}
                  setPolicyData={this.setPolicyData}
                />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}
export default withConfigContext(withTranslation()(DuplicateSelectedPolicy));
