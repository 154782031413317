/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useRef, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { EnrollmentGuideContext } from '../../index';
import ModalActionButtons from '../ModalActionButtons';
import ProgressBar from '../ProgressBar';

const AlternativeEnrollment = props => {
  const guideDetails = useContext(EnrollmentGuideContext);
  const formRef = useRef(null);
  const { t } = useTranslation();

  const [osVersion, setOsVersion] = useState(
    guideDetails.data.alternativeEnrollment,
  );

  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState('alternativeEnrollment');

  const navigateBack = () => {
    props.callBackBackTrigger(pageIndex, props.prevPageIndex);
  };

  const onChange = value => {
    setOsVersion(value);
  };

  const onFinish = values => {
    let obj = {};
    obj.title = t('label_enrollment_guide_os_version_alternative_enrollment');
    obj.key = 'alternativeEnrollment';
    obj.value = osVersion;
    obj.alternativeEnrollmentSuggestion = props.alternativeEnrollmentModeText;
    props.callback(obj, pageIndex);
  };

  return (
    <div>
      <h2>{props.alternativeEnrollmentModeText}</h2>
      <Form
        name="alternativeEnrollment"
        onFinish={onFinish}
        ref={formRef}
        initialValues={{ alternativeEnrollment: osVersion }}
      >
        <Form.Item
          name="alternativeEnrollment"
          label={t('label_enrollment_guide_os_version_alternative_enrollment')}
          rules={[
            {
              required: true,
              message: t('form_fieldRequiredMsg'),
            },
          ]}
        >
          <Select onChange={onChange}>
            <Select.Option
              key="fiveAbove"
              value={t('label_alternativeOptionOneEnrollmentGuide')}
            >
              {t('label_alternativeOptionOneEnrollmentGuide')}
            </Select.Option>
            <Select.Option
              key="sevenAbove"
              value={t('label_alternativeOptionTwoEnrollmentGuide')}
            >
              {t('label_alternativeOptionTwoEnrollmentGuide')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <ModalActionButtons navigateBack={navigateBack} />
        </Form.Item>
      </Form>
      <ProgressBar progress={80} />
    </div>
  );
};

export default withTranslation()(AlternativeEnrollment);
