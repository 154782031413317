/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Divider, Form, PageHeader } from 'antd';
import ReactQuill from 'react-quill';
import { withTranslation } from 'react-i18next';

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'link',
  'image',
];

class iOSEULA extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { platformConfigData } = this.props;
    let initialValues = {};
    for (let config of platformConfigData) {
      if (config.name.toString() === 'iosEula') {
        initialValues.iosEula = config.value;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    this.props.callback(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_iOSEndUserLicenceAgreement')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formLayout}
            labelAlign="right"
            layout="horizontal"
            name="iOSEULAForm"
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Form.Item label="EULA" name="iosEula">
              <ReactQuill
                theme={'snow'}
                modules={modules}
                formats={formats}
                placeholder={t('license_infoTxt')}
              />
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(iOSEULA);
