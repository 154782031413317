/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  PageHeader,
  Spin,
  Upload,
  Typography,
  Row,
  Radio,
  Popconfirm,
  Tooltip,
} from 'antd';
import ReactQuill from 'react-quill';
import { withTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { withConfigContext } from '../../../../../../../../../../../../components/ConfigContext';
import {
  getBase64String,
  getBase64FileFromFileUpload,
  imageUrlToAntDUploadObject,
} from '../../../../../../../../../../../../services/utils/fileUtils';
import Modal from 'antd/es/modal/Modal';
import {
  handleApiError,
  handleApiSuccess,
} from '../../../../../../../../../../../../services/utils/errorHandler';
import { isHtmlOnly } from '../../../../../../../../../../../../services/utils/commonUtils';
import axios from 'axios';
import { randomizeImageUrlToAvoidLoadingFromCache } from '../../../../../../../../../../../../services/utils/urlUtils';

const { Text } = Typography;

const imageTypeRadioButtonStyle = {
  buttonStyle: 'solid',
  optionType: 'button',
  size: 'small',
};

const modules = {
  toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
};

const formats = ['bold', 'italic', 'underline', 'strike', 'link'];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
    xl: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 12 },
  },
};
export const ImageTypes = {
  URL: {
    type: 'URL',
  },
  UPLOAD: {
    type: 'BASE64',
  },
};

class ThemeConfigurationForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      favicons: [],
      logos: [],
      logoIcons: [],
      footerText: '',
      loading: false,
      previewVisible: false,
      previewImage: '',
      faviconUploadHelperText: '',
      logoUploadHelperText: '',
      logoIconUploadHelperText: '',
      isFormValid: false,
      faviconImageTypeOption: this.imageTypeOptions()[0].value,
      logoImageTypeOption: this.imageTypeOptions()[0].value,
      logoIconImageTypeOption: this.imageTypeOptions()[0].value,
    };
  }
  imageTypeOptions = () => {
    const { t } = this.props;
    return [
      {
        label: t('label_upload_image_type'),
        value: ImageTypes.UPLOAD.type,
        tooltip: t('label_form_upload_image_tooltip'),
      },
      {
        label: t('label_url_image_type'),
        value: ImageTypes.URL.type,
        tooltip: t('label_form_url_image_tooltip'),
      },
    ];
  };
  componentDidMount() {
    this.setInitialValues();
  }

  getWhiteLabelApi = () => {
    let config = this.props.context;
    return (
      window.location.origin +
      config.serverConfig.invoker.uri +
      config.serverConfig.invoker.deviceMgt +
      '/whitelabel'
    );
  };

  refreshTheme = () => {
    const { refreshTheme } = this.props;
    refreshTheme().then(_ => {
      this.setInitialValues();
    });
  };

  setToDefaultValues = async () => {
    this.setState({ loading: true });
    const { t } = this.props;
    try {
      let whitelabelResetUrl = this.getWhiteLabelApi() + '/reset';
      axios.put(whitelabelResetUrl).then(_ => {
        handleApiSuccess(
          t('api_successMsg'),
          t('label_theme_reset_success_message'),
        );
        this.refreshTheme();
      });
    } catch (e) {
      handleApiError(e, t('label_theme_reset_fail_message'), t);
    }
    this.setState({ loading: false });
  };

  setInitialValues = async () => {
    this.setState({ loading: true });
    const { theme, t, context } = this.props;
    let config = this.props.context;
    let initialValues = {
      logoUrl: '',
      faviconUrl: '',
      logoIconUrl: '',
    };
    let whitelabelApiUrl =
      window.location.origin +
      '/api' +
      context.serverConfig.invoker.deviceMgt +
      '/whitelabel';
    let logoUrl =
      theme.logo.logoUrl ||
      whitelabelApiUrl + '/' + config.userDomain + '/logo';
    let faviconUrl =
      theme.favicon.faviconUrl ||
      whitelabelApiUrl + '/' + config.userDomain + '/favicon';
    let logoIconUrl =
      theme.logoIcon.logoIconUrl ||
      whitelabelApiUrl + '/' + config.userDomain + '/icon';
    let footerText = theme.footerText || '';
    let appTitle = theme.appTitle;
    try {
      let initialState = {};
      let res = await axios.get(this.getWhiteLabelApi());
      let whitelabelTheme = res.data.data;
      footerText = whitelabelTheme.footerText;
      appTitle = whitelabelTheme.appTitle;
      let logo = whitelabelTheme.logoImage.imageLocation;
      let favicon = whitelabelTheme.faviconImage.imageLocation;
      let logoIcon = whitelabelTheme.logoIconImage.imageLocation;
      let faviconImageTypeOption = ImageTypes.URL.type;
      let logoImageTypeOption = ImageTypes.URL.type;
      let logoIconImageTypeOption = ImageTypes.URL.type;
      let favicons = [];
      let logos = [];
      let logoIcons = [];
      if (
        whitelabelTheme.faviconImage.imageLocationType !== ImageTypes.URL.type
      ) {
        try {
          favicon = await imageUrlToAntDUploadObject(
            faviconUrl,
            1,
            theme.favicon.faviconName,
          );
          favicons = [favicon];
        } catch (err) {
          favicon = null;
        }
        initialValues.favicon = favicon;
        faviconImageTypeOption = ImageTypes.UPLOAD.type;
      } else {
        initialValues.faviconUrl = favicon;
      }
      if (whitelabelTheme.logoImage.imageLocationType !== ImageTypes.URL.type) {
        try {
          logo = await imageUrlToAntDUploadObject(
            logoUrl,
            2,
            theme.logo.logoName,
          );
          logos = [logo];
        } catch (error) {
          logo = null;
        }
        initialValues.logo = logo;
        logoImageTypeOption = ImageTypes.UPLOAD.type;
      } else {
        initialValues.logoUrl = logo;
      }
      if (
        whitelabelTheme.logoIconImage.imageLocationType !== ImageTypes.URL.type
      ) {
        try {
          logoIcon = await imageUrlToAntDUploadObject(
            logoIconUrl,
            3,
            theme.logoIcon.logoIconName,
          );
          logoIcons = [logoIcon];
        } catch (error) {
          logoIcon = null;
        }
        initialValues.logoIcon = logoIcon;
        logoIconImageTypeOption = ImageTypes.UPLOAD.type;
      } else {
        initialValues.logoIconUrl = logoIcon;
      }
      initialValues.footerText = footerText;
      initialValues.appTitle = appTitle;
      initialState.faviconImageTypeOption = faviconImageTypeOption;
      initialState.logoImageTypeOption = logoImageTypeOption;
      initialState.logoIconImageTypeOption = logoIconImageTypeOption;
      initialState.logos = logos;
      initialState.favicons = favicons;
      initialState.logoIcons = logoIcons;
      this.formRef.current.setFieldsValue(initialValues);
      this.setState(initialState);
    } catch (e) {
      handleApiError(e, t('api_loadError', { label: t('label_theme') }), t);
    }
    this.setState({ loading: false });
  };

  validateUrl = (formFieldName, urlString) => {
    const { t } = this.props;
    let url;
    try {
      url = new URL(urlString);
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        this.formRef.current.setFields([
          {
            name: formFieldName,
            value: urlString,
            errors: [t('label_url_protocol_invalid_error')],
          },
        ]);
        return false;
      }
    } catch (error) {
      this.formRef.current.setFields([
        {
          name: formFieldName,
          value: urlString,
          errors: [t('label_url_invalid_error')],
        },
      ]);
      return false;
    }
    return true;
  };

  onSave = values => {
    const { t } = this.props;
    const { logoUrl, faviconUrl, logoIconUrl, footerText, appTitle } = values;
    let {
      favicons,
      logos,
      logoIcons,
      faviconUploadHelperText,
      logoUploadHelperText,
      logoIconUploadHelperText,
      faviconImageTypeOption,
      logoImageTypeOption,
      logoIconImageTypeOption,
    } = this.state;
    let isFormValid = true;
    if (
      faviconImageTypeOption === ImageTypes.UPLOAD.type &&
      favicons.length < 1
    ) {
      isFormValid = false;
      faviconUploadHelperText = t(
        'label_theme_favicon_upload_form_required_message',
      );
    } else if (faviconUrl && !this.validateUrl('faviconUrl', faviconUrl)) {
      isFormValid = false;
    }

    if (logoImageTypeOption === ImageTypes.UPLOAD.type && logos.length < 1) {
      isFormValid = false;
      logoUploadHelperText = t('label_theme_logo_upload_form_required_message');
    } else if (logoUrl && !this.validateUrl('logoUrl', logoUrl)) {
      isFormValid = false;
    }

    if (
      logoIconImageTypeOption === ImageTypes.UPLOAD.type &&
      logoIcons.length < 1
    ) {
      isFormValid = false;
      logoIconUploadHelperText = t(
        'label_theme_logo_icon_upload_form_required_message',
      );
    } else if (logoIconUrl && !this.validateUrl('logoIconUrl', logoIconUrl)) {
      isFormValid = false;
    }

    if (isHtmlOnly(footerText)) {
      isFormValid = false;
      this.formRef.current.setFields([
        {
          name: 'footerText',
          value: footerText,
          errors: [t('label_footer_form_required_message')],
        },
      ]);
    }

    if (!isFormValid) {
      this.setState({
        isFormValid: isFormValid,
        faviconUploadHelperText: faviconUploadHelperText,
        logoUploadHelperText: logoUploadHelperText,
        logoIconUploadHelperText: logoIconUploadHelperText,
      });
      return;
    }

    let logo = logoUrl ? logoUrl : logos[0];
    let favicon = faviconUrl ? faviconUrl : favicons[0];
    let logoIcon = logoIconUrl ? logoIconUrl : logoIcons[0];
    this.updateTheme(favicon, logo, logoIcon, footerText, appTitle);
  };

  updateTheme = async (favicon, logo, logoIcon, footerText, appTitle) => {
    let { t } = this.props;
    let {
      faviconImageTypeOption,
      logoImageTypeOption,
      logoIconImageTypeOption,
    } = this.state;
    let faviconPayload = await this.constructImagePayload(
      favicon,
      faviconImageTypeOption,
    );
    let logoPayload = await this.constructImagePayload(
      logo,
      logoImageTypeOption,
    );
    let logoIconPayload = await this.constructImagePayload(
      logoIcon,
      logoIconImageTypeOption,
    );

    let updatedThemePayload = {
      favicon: faviconPayload,
      logo: logoPayload,
      logoIcon: logoIconPayload,
      footerText,
      appTitle,
    };
    let whitelabelApiUrl = this.getWhiteLabelApi();
    return axios
      .put(whitelabelApiUrl, updatedThemePayload)
      .then(_ => {
        handleApiSuccess(
          t('api_successMsg'),
          t('label_theme_update_success_message'),
        );
        this.refreshTheme();
      })
      .catch(err => {
        handleApiError(err, t('label_theme_update_fail_message'), t);
      });
  };

  constructImagePayload = async (image, imageType) => {
    let imagePayload = { imageType };
    if (imageType === ImageTypes.UPLOAD.type) {
      let imageBase64 = await getBase64FileFromFileUpload(image);
      imagePayload.image = imageBase64;
      return imagePayload;
    }
    imagePayload.image = image;
    return imagePayload;
  };

  handleLogoChange = ({ fileList }) => {
    if (fileList.length === 1) {
      this.setState({
        logoUploadHelperText: '',
      });
    }
    this.setState({
      logos: fileList,
    });
  };

  handleFaviconChange = ({ fileList }) => {
    if (fileList.length === 1) {
      this.setState({
        faviconUploadHelperText: '',
      });
    }
    this.setState({
      favicons: fileList,
    });
  };

  handleLogoIconChange = ({ fileList }) => {
    if (fileList.length === 1) {
      this.setState({
        logoIconUploadHelperText: '',
      });
    }
    this.setState({
      logoIcons: fileList,
    });
  };

  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64String(file.originFileObj);
    }
    let previewImage = file.preview;
    if (file.url) {
      previewImage = randomizeImageUrlToAvoidLoadingFromCache(file.url);
    }
    this.setState({
      previewImage: previewImage,
      previewVisible: true,
    });
  };

  onFaviconImageTypeChange = e => {
    this.setState({ faviconImageTypeOption: e.target.value });
  };

  onLogoImageTypeChange = e => {
    this.setState({ logoImageTypeOption: e.target.value });
  };

  onLogoIconImageTypeChange = e => {
    this.setState({ logoIconImageTypeOption: e.target.value });
  };

  setFooterText = value => {
    this.setState({ footerText: value });
  };

  render() {
    const { t } = this.props;
    const {
      favicons,
      logos,
      logoIcons,
      footerText,
      faviconImageTypeOption,
      logoImageTypeOption,
      logoIconImageTypeOption,
      loading,
      previewVisible,
      previewImage,
      faviconUploadHelperText,
      logoUploadHelperText,
      logoIconUploadHelperText,
    } = this.state;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_themeConfigs')}
            />
          </Divider>
        </div>
        <div>
          <Spin tip={t('label_loading')} size="medium" spinning={loading}>
            <Form
              {...formItemLayout}
              name="GeneralConfigurationForm"
              onFinish={this.onSave}
              ref={this.formRef}
            >
              <Form.Item
                {...formItemLayout}
                label={t('label_theme_app_title_formLabel')}
                name="appTitle"
                rules={[
                  {
                    required: true,
                    message: t('label_theme_app_title_required_message'),
                  },
                ]}
                style={{
                  marginBottom: 10,
                }}
              >
                <Input placeholder={t('label_theme_app_title_placeholder')} />
              </Form.Item>
              <Row
                style={{
                  marginBottom: 20,
                }}
              >
                <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                  <Text type="secondary">
                    {t('label_theme_app_title_tooltip')}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                  <Radio.Group
                    value={faviconImageTypeOption}
                    onChange={this.onFaviconImageTypeChange}
                    {...imageTypeRadioButtonStyle}
                    style={{ marginBottom: '10px' }}
                  >
                    {this.imageTypeOptions().map((option, i) => {
                      return (
                        <Tooltip key={option.value + i} title={option.tooltip}>
                          <Radio.Button
                            key={option.value + i}
                            value={option.value}
                          >
                            {option.label}
                          </Radio.Button>
                        </Tooltip>
                      );
                    })}
                  </Radio.Group>
                </Col>
              </Row>

              {faviconImageTypeOption === ImageTypes.UPLOAD.type ? (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name="favicon"
                    label={t('label_theme_favicon_formLabel')}
                    validateStatus="error"
                    help={
                      faviconUploadHelperText ? faviconUploadHelperText : null
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Upload
                      name="favicon"
                      listType="picture-card"
                      fileList={favicons}
                      onChange={this.handleFaviconChange}
                      beforeUpload={() => false}
                      onPreview={this.handlePreview}
                    >
                      {favicons.length === 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">
                            {t('label_select')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                  <Row style={{ marginBottom: 20 }}>
                    <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                    <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                      <Text type="secondary">
                        {t('label_recommended')} : 16px x 16px
                      </Text>
                    </Col>
                  </Row>
                </>
              ) : (
                <Form.Item
                  {...formItemLayout}
                  label={t('label_theme_favicon_formLabel')}
                  name="faviconUrl"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'label_theme_favicon_url_form_required_message',
                      ),
                    },
                  ]}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Input placeholder="https://images/favicon.png" />
                </Form.Item>
              )}
              <Row>
                <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                  <Radio.Group
                    value={logoImageTypeOption}
                    onChange={this.onLogoImageTypeChange}
                    {...imageTypeRadioButtonStyle}
                    style={{ marginBottom: '10px' }}
                  >
                    {this.imageTypeOptions().map((option, i) => {
                      return (
                        <Tooltip key={option.value + i} title={option.tooltip}>
                          <Radio.Button
                            key={option.value + i}
                            value={option.value}
                          >
                            {option.label}
                          </Radio.Button>
                        </Tooltip>
                      );
                    })}
                  </Radio.Group>
                </Col>
              </Row>
              {ImageTypes.UPLOAD.type === logoImageTypeOption ? (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name="logo"
                    label={t('label_theme_logo_formLabel')}
                    validateStatus="error"
                    help={logoUploadHelperText ? logoUploadHelperText : null}
                    style={{
                      marginBottom: 0,
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      name="logo"
                      listType="picture-card"
                      fileList={logos}
                      onChange={this.handleLogoChange}
                      beforeUpload={() => false}
                      onPreview={this.handlePreview}
                    >
                      {logos.length === 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">
                            {t('label_select')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                  <Row style={{ marginBottom: 20 }}>
                    <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                    <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                      <Text type="secondary">
                        {t('label_recommended')} : min: 320px max: 1920px
                      </Text>
                    </Col>
                  </Row>
                </>
              ) : (
                <Form.Item
                  {...formItemLayout}
                  label={t('label_theme_logo_formLabel')}
                  name="logoUrl"
                  rules={[
                    {
                      required: true,
                      message: t('label_theme_logo_url_form_required_message'),
                    },
                  ]}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Input placeholder="https://images/logo.png" />
                </Form.Item>
              )}
              <Row>
                <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                  <Radio.Group
                    value={logoIconImageTypeOption}
                    onChange={this.onLogoIconImageTypeChange}
                    {...imageTypeRadioButtonStyle}
                    style={{ marginBottom: '10px' }}
                  >
                    {this.imageTypeOptions().map((option, i) => {
                      return (
                        <Tooltip key={option.value + i} title={option.tooltip}>
                          <Radio.Button
                            key={option.value + i}
                            value={option.value}
                          >
                            {option.label}
                          </Radio.Button>
                        </Tooltip>
                      );
                    })}
                  </Radio.Group>
                </Col>
              </Row>
              {ImageTypes.UPLOAD.type === logoIconImageTypeOption ? (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name="logoIcon"
                    label={t('label_theme_logoIcon_formLabel')}
                    validateStatus="error"
                    help={
                      logoIconUploadHelperText ? logoIconUploadHelperText : null
                    }
                    style={{
                      marginBottom: 0,
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      name="logoIcon"
                      listType="picture-card"
                      fileList={logoIcons}
                      onChange={this.handleLogoIconChange}
                      beforeUpload={() => false}
                      onPreview={this.handlePreview}
                    >
                      {logoIcons.length === 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">
                            {t('label_select')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                  <Row>
                    <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                    <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                      <Text type="secondary">
                        {t('label_recommended')} : min: 320px max: 700px
                      </Text>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                    <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                      <Text type="secondary">
                        {t('label_theme_logo_icon_tooltip')}
                      </Text>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Form.Item
                    {...formItemLayout}
                    label={t('label_theme_logoIcon_formLabel')}
                    name="logoIconUrl"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'label_theme_logo_icon_url_form_required_message',
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="https://images/logo-icon.png" />
                  </Form.Item>
                  <Row
                    style={{
                      marginBottom: 20,
                      marginTop: -10,
                    }}
                  >
                    <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                    <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                      <Text type="secondary">
                        {t('label_theme_logo_icon_tooltip')}
                      </Text>
                    </Col>
                  </Row>
                </>
              )}
              <Form.Item
                {...formItemLayout}
                label={t('label_theme_footer_formLabel')}
                rules={[
                  {
                    required: true,
                    message: t('label_footer_form_required_message'),
                  },
                ]}
                name="footerText"
                initialValue={footerText}
                style={{
                  marginBottom: 10,
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={footerText}
                  modules={modules}
                  formats={formats}
                  onChange={this.setFooterText}
                  placeholder={t('label_theme_footer_formPlaceholder')}
                />
              </Form.Item>
              <Row
                style={{
                  marginBottom: 20,
                }}
              >
                <Col xs={24} sm={24} md={16} lg={12} xl={7} />
                <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                  <Text type="secondary">
                    {t('label_recommended')} : max. 100 char
                  </Text>
                </Col>
              </Row>
              <Form.Item style={{ float: 'right' }}>
                <Button type="primary" htmlType="submit">
                  {t('label_save')}
                </Button>
              </Form.Item>
              <Form.Item style={{ marginRight: '10px', float: 'right' }}>
                <Popconfirm
                  title={'Are you sure, you want to reset the theme?'}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={this.setToDefaultValues}
                >
                  <Button>{t('label_reset_to_default')}</Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          </Spin>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handlePreviewCancel}
          >
            <img
              alt={t('label_preview_image')}
              style={{ width: '100%' }}
              src={previewImage}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(ThemeConfigurationForm));
