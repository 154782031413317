/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import ConfigContext from '../../../../components/ConfigContext';
import TimeAgo from 'timeago-react';
import { PageHeader, Typography, Table, Row, Col, Tooltip } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment-timezone';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title } = Typography;

export const VirusHistory = props => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  const routes = (appName, t) => {
    return [
      {
        path: `/${appName}`,
        breadcrumbName: t('home_menu_home'),
      },
      {
        breadcrumbName: t('home_menu_virusScan'),
      },
    ];
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      width: 100,
    },
    {
      title: 'Severity',
      dataIndex: 'Severity',
      width: 100,
    },
    {
      title: 'Current Status',
      dataIndex: 'CurrentStatus',
      width: 100,
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      width: 100,
    },
    {
      title: 'Last Detection',
      dataIndex: 'LastThreatStatusChangeTime',
      width: 100,
      key: 'LastThreatStatusChangeTime',

      // eslint-disable-next-line react/display-name
      render: LastThreatStatusChangeTime => {
        return (
          <Tooltip
            title={moment
              .utc(LastThreatStatusChangeTime)
              .tz(moment.tz.guess())
              .format('LLLL')}
          >
            <TimeAgo datetime={LastThreatStatusChangeTime} locale="en_US" />
          </Tooltip>
        );
      },
    },
  ];

  let virusData = [];
  if (props.location.state && props.location.state.detail) {
    virusData = JSON.parse(props.location.state.detail);
  }

  let data = virusData;

  return (
    <div>
      <PageHeader
        className={styles.pageHeader}
        title={
          <Title level={1}>
            <span className={styles.icon}>
              <AppstoreOutlined />
            </span>
            {t('label_virusScanResults')}
          </Title>
        }
        ghost={false}
        breadcrumb={
          <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
        }
      />
      <div className={styles.container}>
        <Row>
          <Col>
            <p style={{ marginTop: 20 }}>
              <b> {t('label_noOfVirus')}</b> {data.length}
            </p>
          </Col>
        </Row>
        <div className={styles.tableContainer}>
          <Table
            expandable={{
              // eslint-disable-next-line react/display-name
              expandedRowRender: record => (
                <div style={{ marginLeft: 70 }}>
                  <p>
                    <b>{t('label_executionStatus')} </b>
                    {record.ExecutionStatus}
                  </p>
                  <p>
                    <b>{t('label_initialDetectionTime')} </b>
                    {record.InitialDetectionTime}
                  </p>
                  <p>
                    <b>{t('label_url')} </b>
                    {record.URL}
                  </p>
                </div>
              ),
              rowExpandable: record => record.name !== 'Not Expandable',
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
    </div>
  );
};

export default VirusHistory;
