/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
  Form,
  Button,
  Col,
  Divider,
  Input,
  PageHeader,
  Row,
  Select,
  Tooltip,
  Checkbox,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

const communicationTypes = [
  { name: 'Local Polling', key: 'label_localPolling', value: '1' },
  { name: 'Firebase Cloud Messaging ( FCM )', key: 'label_fcm', value: '2' },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const buttonItemLayout = {
  wrapperCol: { span: 22 },
};

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'link',
  'image',
];

const { Option } = Select;

class CommunicationProtocol extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      notifierType: '1',
      notifierFrequency: null,
      androidEula: null,
      fcmAPIKey: null,
    };
  }

  componentDidMount() {
    let platformConfigData = [...this.props.platformConfigData];

    let state = {};
    for (let platformConfig of platformConfigData) {
      if (platformConfig.name === 'notifierType') {
        state.notifierType = platformConfig.value;
      }
      if (platformConfig.name === 'notifierFrequency') {
        state.notifierFrequency = (platformConfig.value / 1000).toString();
      }
      if (platformConfig.name === 'androidEula') {
        state.androidEula = platformConfig.value;
      }
      if (platformConfig.name === 'fcmAPIKey') {
        state.fcmAPIKey = platformConfig.value;
      }
      if (platformConfig.name === 'isFirewallEnabled') {
        state.isFirewallEnabled = platformConfig.value;
      }
      if (platformConfig.name === 'isContinuousLocationEnabled') {
        state.isContinuousLocationEnabled = platformConfig.value;
      }
      if (platformConfig.name === 'locationPublishFrequency') {
        state.locationPublishFrequency = platformConfig.value;
      }
    }
    this.setState(state);
    this.formRef.current.setFieldsValue(state);
  }

  onFinish = values => {
    if (values.notifierFrequency) {
      values.notifierFrequency = (values.notifierFrequency * 1000).toString();
    }
    this.props.callback(values);
  };

  handleNotifierTypeChange = value => {
    this.setState({ notifierType: value });
  };

  render() {
    let { notifierType } = this.state;
    const { t } = this.props;
    let notifierKey = notifierType === '1' ? 'notifierFrequency' : 'fcmAPIKey';
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_androidCommunicationProtocol')}
            />
          </Divider>
        </div>
        <div>
          <Row>
            <Col md={24}>
              <Form
                {...formItemLayout}
                name="androidCommunicationProtocol"
                onFinish={this.onFinish}
                ref={this.formRef}
              >
                <div>
                  <Form.Item
                    name="notifierType"
                    label={
                      <span>
                        {t('label_typeOfCommunication')}&nbsp;
                        <Tooltip title={t('typeOfCommunication_infoTxt')}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    initialValue="1"
                    rules={[
                      {
                        required: true,
                        message: t('form_communicationTypeRequiredMsg'),
                      },
                    ]}
                  >
                    <Select
                      key={'nt'}
                      style={{ width: '100%' }}
                      placeholder={t(
                        'form_androidCommunicationTypeRequiredMsg',
                      )}
                      onChange={this.handleNotifierTypeChange}
                    >
                      {communicationTypes.map((type, index) => {
                        return (
                          <Option key={index} value={type.value}>
                            {t(type.key)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {notifierType === '1' && (
                    <Form.Item
                      name={notifierKey}
                      initialValue={60}
                      label={
                        <span>
                          {t('label_pollingInterval')}&nbsp;
                          <Tooltip title={t('form_configIntervalValidMsg')}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: t('form_valueRequiredMsg'),
                        },
                        {
                          validator(rule, value) {
                            if (!value || value >= 60) {
                              return Promise.resolve();
                            }
                            return Promise.reject(t('minimumPolling_txt'));
                          },
                        },
                      ]}
                    >
                      <Input placeholder={t('form_configIntervalValidMsg')} />
                    </Form.Item>
                  )}

                  {notifierType === '2' && (
                    <Form.Item
                      name={notifierKey}
                      label={
                        <span>
                          {t('label_apiKey')}&nbsp;
                          <Tooltip title={t('addAPIKey_txt')}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: t('form_valueRequiredMsg'),
                        },
                      ]}
                    >
                      <Input placeholder={t('addAPIKey_txt')} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="androidEula"
                    label={t('label_endUserLicenseAgreement')}
                  >
                    <ReactQuill
                      theme={'snow'}
                      modules={modules}
                      formats={formats}
                      placeholder={t('license_infoTxt')}
                    />
                  </Form.Item>
                  <div>
                    <Divider>
                      <PageHeader
                        className="site-page-header-responsive"
                        subTitle={t('label_androidLocationConfigs')}
                      />
                    </Divider>
                  </div>
                  <Form.Item
                    {...tailLayout}
                    name="isContinuousLocationEnabled"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <span>
                        {t('label_enableContinuousDeviceLocationPublishing')}
                        &nbsp;
                        <Tooltip
                          title={t(
                            'label_enableContinuousDeviceLocationPublishing',
                          )}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      return (
                        prevValues.isContinuousLocationEnabled !==
                        currentValues.isContinuousLocationEnabled
                      );
                    }}
                  >
                    {({ getFieldValue }) => {
                      return getFieldValue('isContinuousLocationEnabled') ===
                        true ? (
                        <div>
                          <Form.Item
                            initialValue={'3'}
                            label={
                              <span>
                                {t('label_locationPublishFrequency')}&nbsp;
                                <Tooltip
                                  title={t('label_locationPublishFrequency')}
                                >
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </span>
                            }
                            name="locationPublishFrequency"
                          >
                            <Select>
                              <Option value="3">
                                {t('label_secondsCount', { count: '3' })}
                              </Option>
                              <Option value="5">
                                {t('label_secondsCount', { count: '5' })}
                              </Option>
                              <Option value="10">
                                {t('label_secondsCount', { count: '10' })}
                              </Option>
                              <Option value="15">
                                {t('label_secondsCount', { count: '15' })}
                              </Option>
                              <Option value="30">
                                {t('label_secondsCount', { count: '30' })}
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      ) : null;
                    }}
                  </Form.Item>
                  <div>
                    <Divider>
                      <PageHeader
                        className="site-page-header-responsive"
                        subTitle={t('label_androidFirewallConfigs')}
                      />
                    </Divider>
                  </div>
                  <Form.Item
                    {...tailLayout}
                    name="isFirewallEnabled"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <span>
                        {t('label_enableIoTSFirewallService')}&nbsp;
                        <Tooltip
                          title={t('enableIoTSFirewallService_descriptionTxt')}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item {...buttonItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: 'right' }}
                    >
                      {t('label_save')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CommunicationProtocol));
