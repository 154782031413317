/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { PageHeader, Row, Col, Typography } from 'antd';
import styles from '../../styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import IframeWrapper from '../../../../components/IframeWrapper';
import { ReconciliationOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { RoutesContext } from '../../../../index';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/analytics`,
      breadcrumbName: t('home_menu_analytics'),
    },
    {
      breadcrumbName: t('label_deviceAssignment'),
    },
  ];
};

class DeviceAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    this.state = {
      selectedLanguage: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('deviceAssignment');
  }

  render() {
    const { t } = this.props;
    const { selectedLanguage } = this.state;
    const grafanaEmbedLinks = this.config.grafanaConfig.deviceAssignment;
    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <ReconciliationOutlined />
                </span>
                {t('label_deviceAssignment')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <div className={styles.container}>
            <Row>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.overview +
                      '&panelId=5' +
                      '&var-label_panelName5=' +
                      selectedLanguage +
                      '&var-label_ungrouped=' +
                      selectedLanguage +
                      '&var-label_deviceCount=' +
                      selectedLanguage
                    }
                  />
                </div>
              </Col>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.overview +
                      '&panelId=2' +
                      '&var-label_panelName2=' +
                      selectedLanguage +
                      '&var-label_grouped=' +
                      selectedLanguage +
                      '&var-label_ungrouped=' +
                      selectedLanguage
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

DeviceAssignment.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DeviceAssignment));
