/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useRef, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import { EnrollmentGuideContext } from '../../index';
import TextArea from 'antd/es/input/TextArea';
import ProgressBar from '../ProgressBar';

const Feedback = props => {
  const guideDetails = useContext(EnrollmentGuideContext);
  const formRef = useRef(null);
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [features, setFeatures] = useState(
    guideDetails.data.feedback ? guideDetails.data.feedback[0].value : '',
  );
  // eslint-disable-next-line no-unused-vars
  const [feedback, setFeedback] = useState(
    guideDetails.data.feedback ? guideDetails.data.feedback[1].value : '',
  );
  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState('feedBack');

  const onFinish = values => {
    let objArr = [];
    let objFeature = {};
    let objFeedback = {};
    objFeature.title = t('label_enrollment_guide_features');
    objFeature.key = 'features';

    values.features === undefined
      ? (objFeature.value = '-')
      : (objFeature.value = values.features);
    objArr.push(objFeature);
    objFeedback.title = t('label_enrollment_guide_feedback');
    objFeedback.key = 'feedback';

    values.feedback === undefined
      ? (objFeedback.value = '-')
      : (objFeedback.value = values.feedback);
    objArr.push(objFeedback);
    props.callback(objArr, pageIndex);
  };

  const navigateBack = () => {
    let prevPage = '';
    if (props.isAlternativeEnrollmentMode) {
      prevPage = 'alternativeEnrollmentMode';
    } else {
      prevPage = 'enrollmentMode';
    }
    props.callBackBackTrigger(pageIndex, prevPage);
  };

  return (
    <div>
      <Form
        name="feedbackAndFeatures"
        onFinish={e => onFinish(e)}
        ref={formRef}
      >
        <Form.Item name="features" label={t('label_enrollment_guide_features')}>
          <TextArea defaultValue={features} />
        </Form.Item>
        <Form.Item name="feedback" label={t('label_enrollment_guide_feedback')}>
          <TextArea defaultValue={feedback} />
        </Form.Item>
        <Form.Item>
          <div style={{ float: 'right' }}>
            <Button
              type="primary"
              onClick={navigateBack}
              style={{ marginRight: 30 }}
            >
              {t('label_back')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('label_submit')}
            </Button>
          </div>
        </Form.Item>
      </Form>
      <ProgressBar progress={90} />
    </div>
  );
};

export default withTranslation()(Feedback);
