/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Input, Button, Modal } from 'antd';
import SearchQueryTable from '../SearchQueryTable';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class SearchQueryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    const { t } = this.props;
    const state = {
      select: {
        options: [
          { definedValue: 'AND', displayingValue: t('label_and') },
          { definedValue: 'OR', displayingValue: t('label_or') },
        ],
      },
      key: 'state',
      name: t('label_state'),
      value: 'AND',
    };

    const key = {
      select: {
        options: [
          {
            definedValue: 'deviceModel',
            displayingValue: t('label_deviceModel'),
          },
          {
            definedValue: 'PhoneNumber',
            displayingValue: t('label_phone_number'),
          },
          { definedValue: 'vendor', displayingValue: t('label_vendor') },
          { definedValue: 'osVersion', displayingValue: t('label_os_version') },
          {
            definedValue: 'batteryLevel',
            displayingValue: t('label_battery_leve'),
          },
          {
            definedValue: 'internalTotalMemory',
            displayingValue: t('label_internal_tot_memory'),
          },
          {
            definedValue: 'internalAvailableMemory',
            displayingValue: t('label_internal_available_memory'),
          },
          {
            definedValue: 'externalTotalMemory',
            displayingValue: t('label_external_tot_memory'),
          },
          {
            definedValue: 'externalAvailableMemory',
            displayingValue: t('label_external_available_memory'),
          },
          {
            definedValue: 'connectionType',
            displayingValue: t('label_connection_type'),
          },
          {
            definedValue: 'ssid',
            displayingValue: t('label_ssid'),
          },
          {
            definedValue: 'IMEI',
            displayingValue: t('label_imei'),
          },
          {
            definedValue: 'cpuUsage',
            displayingValue: t('label_cpu_usage'),
          },
          {
            definedValue: 'totalRAMMemory',
            displayingValue: t('label_tot_ram_memory'),
          },
          {
            definedValue: 'availableRAMMemory',
            displayingValue: t('label_available_ram_memory'),
          },
          {
            definedValue: 'pluggedIn',
            displayingValue: t('label_plugged_in'),
          },
        ],
      },
      key: 'key',
      name: t('label_key'),
      value: 'deviceModel',
    };

    const operator = {
      select: {
        options: [
          { definedValue: '=', displayingValue: '=' },
          { definedValue: '!=', displayingValue: '!=' },
          { definedValue: '%', displayingValue: '%' },
        ],
      },
      key: 'operator',
      name: t('label_operator'),
      value: '=',
    };

    const value = {
      input: { placeholderValue: t('label_value'), type: 'Text' },
      key: 'value',
      name: t('label_value'),
      value: '',
    };

    this.columns = [state, key, operator, value];

    this.params = [
      { index: 0, state: 'AND', key: 'deviceModel', operator: '=', value: '' },
    ];
    this.location = '';
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
    if (this.location !== '') {
      this.params.forEach(param => {
        param.location = this.location;
      });
    }
    this.props.callback(this.params);
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  getSearchQueryParameters = parameters => {
    this.params = parameters;
  };

  onChangeLocationInput = e => {
    this.location = e.target.value;
  };

  onBlurLocationInput = e => {
    this.params.forEach(param => {
      param.location = e.target.value;
    });
    this.props.callback(this.params);
  };

  render() {
    const { t } = this.props;
    const initialValue = [
      {
        index: 0,
        state: 'AND',
        key: 'deviceModel',
        operator: '=',
        value: '',
      },
    ];
    const itemData = {
      isRequired: false,
      key: 'searchQueryTable',
      label: t('label_search_query_table'),
      table: {
        columns: this.columns,
      },
      value: initialValue,
    };
    return (
      <div>
        <Input
          placeholder={t('label_location')}
          size={'large'}
          className={styles.locationInput}
          onChange={this.onChangeLocationInput}
          onBlur={this.onBlurLocationInput}
        />
        <Button
          size={'large'}
          onClick={this.showModal}
          className={styles.setParameterButton}
        >
          {t('label_setParameters')}
        </Button>
        <Modal
          title={t('label_setSearchQueries')}
          width={'50%'}
          open={this.state.visible}
          okText={t('label_ok')}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
        >
          <SearchQueryTable
            itemData={itemData}
            callback={parameters => this.getSearchQueryParameters(parameters)}
          />
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(SearchQueryModal);
