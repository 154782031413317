/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './services/serviceWorkers/serviceWorker';
import App from './App';
import Login from './scenes/Login';
import Home from './scenes/Home';
import './index.css';
import config from '../public/conf/config.json';
import Geo from './scenes/Home/scenes/Geo/scenes/GeoHistory';
import Notifications from './scenes/Home/scenes/Notifications';
import EnrollDevice from './scenes/Home/scenes/EnrollDevice';
import Groups from './scenes/Home/scenes/Groups';
import Users from './scenes/Home/scenes/Users';
import Policies from './scenes/Home/scenes/Policies/scenes/PolicyListView';
import AddNewPolicy from './scenes/Home/scenes/Policies/scenes/AddNewPolicy';
import Roles from './scenes/Home/scenes/Roles';
import PlatformConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration';
import Certificates from './scenes/Home/scenes/Configurations/scenes/Certificates';
import Devices from './scenes/Home/scenes/Devices';
import DeviceTypes from './scenes/Home/scenes/DeviceTypes';
import Operators from './scenes/Home/scenes/Operators';
import HourlyConsumption from './scenes/Home/scenes/HourlyConsumption';
import Products from './scenes/Home/scenes/Products';
import Flowlayout from './scenes/Home/scenes/FlowLayout';
import WeeklySummary from './scenes/Home/scenes/WeeklySummary';
import OutputPenAssembly from './scenes/Home/scenes/OutputPenAssembly';
import Breakdowns from './scenes/Home/scenes/Breakdowns';
import Energy from './scenes/Home/scenes/Energy';
import Quality from './scenes/Home/scenes/Quality';
import EditSelectedPolicy from './scenes/Home/scenes/Policies/scenes/EditSelectedPolicy';
import DeviceLocations from './scenes/Home/scenes/DeviceLocations';
import PolicyPriorityView from './scenes/Home/scenes/Policies/scenes/PolicyPriorityView';
import AndroidConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AndroidConfiguration';
import GeneralConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration';
import General from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/General';
import Theme from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/Theme';
import Enrollment from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/Enrollment';
import LocationPublisher from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/LocationPublisher';
import AndroidGeneralConfigurations from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AndroidConfiguration/components/AndroidGeneralConfigurations';
import AndroidForWork from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AndroidConfiguration/components/AndroidForWork';
import IOSConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/IOSConfiguration';
import WindowsConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/WindowsConfiguration';
import KioskProvisioning from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AndroidConfiguration/components/KioskProvisioning';
import APNSConfig from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/IOSConfiguration/components/APNSConfig';
import DEPConfig from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/IOSConfiguration/components/DEPConfig';
import MDMProfile from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/IOSConfiguration/components/MDMProfile';
import iOSEULA from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/IOSConfiguration/components/iOSEULA';
import DEPDevices from './scenes/Home/scenes/DEPDevices';
import DEPProfiles from './scenes/Home/scenes/DEPProfiles';
import AtlasSingleDevice from './scenes/Home/scenes/AtlasSingleDevice';
import AtlasSingleOperator from './scenes/Home/scenes/AtlasSingleOperator';
import AtlasSingleProduct from './scenes/Home/scenes/AtlasSingleProduct';
import PolicyConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AndroidConfiguration/components/PolicyConfiguration';
import GeneralConfig from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/WindowsConfiguration/components/GeneralConfig';
import AgentConfig from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/WindowsConfiguration/components/AgentConfig';
import AlertingConfiguration from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AlertingConfiguration';
import GeoAlerts from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/AlertingConfiguration/components/GeoAlerts';
import GeoFences from './scenes/Home/scenes/GeoFences';
import PolicyView from './scenes/Home/scenes/Policies/scenes/PolicyView';
import GeoGroup from './scenes/Home/scenes/Geo/scenes/GeoGroup';
import AddServer from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/AddServer';
import Dashboard from './scenes/Home/scenes/Dashboard';
import Analytics from './scenes/Home/scenes/Analytics';
import DeviceConnectivity from './scenes/Home/scenes/Analytics/components/DeviceConnectivity';
import MobileConnectivity from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/MobileConnectivity';
import WifiConnectivity from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/WifiConnectivity';
import NetworkTypeOverTime from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/NetworkTypeOverTime';
import SignalStrength from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/SignalStrength';
import SimChange from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/SimChange';
import SignalStrengthByDevice from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/SignalStrengthByDevice';
import SimChangeByDevice from './scenes/Home/scenes/Analytics/components/DeviceConnectivity/components/SimChangeByDevice';
import Applications from './scenes/Home/scenes/Analytics/components/Applications';
import DeviceAssignment from './scenes/Home/scenes/Analytics/components/DeviceAssignment';
import DeviceFirmware from './scenes/Home/scenes/Analytics/components/DeviceFirmware';
import DeviceHardware from './scenes/Home/scenes/Analytics/components/DeviceHardware';
import DeviceInventory from './scenes/Home/scenes/Analytics/components/DeviceInventory';
import Operations from './scenes/Home/scenes/Analytics/components/Operations';
import Policy from './scenes/Home/scenes/Analytics/components/Policy';
import BillingConfig from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/Billing';
import Billing from './scenes/Home/scenes/Billing';
import VirusHistory from './scenes/Home/scenes/VirusScanResults';
import Security from './scenes/Home/scenes/Analytics/components/Security';
import DuplicateSelectedPolicy from './scenes/Home/scenes/Policies/scenes/DuplicateSelectedPolicy';
import BillingTable from './scenes/Home/scenes/Billing/components/BillingTable';
import Evaluate from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/EvaluateTenant';
import EnrollIoTDevice from "./scenes/Home/scenes/EnrollIOTDevice";
import HomeDashboard from "./scenes/Home/scenes/Home-Dashboard";

const routes = [
  {
    path: `/${config.appName}/login`,
    exact: true,
    component: Login,
  },
  {
    path: `/${config.appName}`,
    exact: false,
    component: Home,
    routes: [
      {
        path: `/${config.appName}/dashboard`,
        component: Dashboard,
        exact: true,
      },
      {
        path: `/${config.appName}/home`,
        component: HomeDashboard,
        exact: true,
      },
      {
        path: `/${config.appName}/billing`,
        component: Billing,
        exact: true,
      },
      {
        path: `/${config.appName}/billing/:year`,
        component: BillingTable,
        exact: false,
      },
      {
        path: `/${config.appName}/operators`,
        component: Operators,
        exact: true,
      },
      {
        path: `/${config.appName}/consumption`,
        component: HourlyConsumption,
        exact: true,
      },
      {
        path: `/${config.appName}/products`,
        component: Products,
        exact: true,
      },
      {
        path: `/${config.appName}/factory/flowlayouts`,
        component: Flowlayout,
        exact: true,
      },
      {
        path: `/${config.appName}/factory/weeklysummary`,
        component: WeeklySummary,
        exact: true,
      },
      {
        path: `/${config.appName}/output/penassembly`,
        component: OutputPenAssembly,
        exact: true,
      },
      {
        path: `/${config.appName}/breakdowns`,
        component: Breakdowns,
        exact: true,
      },
      {
        path: `/${config.appName}/energy`,
        component: Energy,
        exact: true,
      },
      {
        path: `/${config.appName}/quality`,
        component: Quality,
        exact: true,
      },
      {
        path: `/${config.appName}/devices`,
        component: Devices,
        exact: true,
      },
      {
        path: `/${config.appName}/devices/types`,
        component: DeviceTypes,
        exact: true,
      },
      {
        path: `/${config.appName}/devices/enroll`,
        component: EnrollIoTDevice,
        exact: true,
      },
      {
        path: `/${config.appName}/devices/dep-devices`,
        component: DEPDevices,
        exact: true,
      },
      {
        path: `/${config.appName}/devices/dep-profiles`,
        component: DEPProfiles,
        exact: true,
      },
      {
        path: `/${config.appName}/geo/history/:deviceType/:deviceIdentifier`,
        component: Geo,
        exact: true,
      },
      {
        path: `/${config.appName}/geo/history/:groupId`,
        component: GeoGroup,
        exact: true,
      },
      {
        path: `/${config.appName}/groups`,
        component: Groups,
        exact: true,
      },
      {
        path: `/${config.appName}/users`,
        component: Users,
        exact: true,
      },
      {
        path: `/${config.appName}/policies`,
        component: Policies,
        exact: true,
      },
      {
        path: `/${config.appName}/policy/add`,
        component: AddNewPolicy,
        exact: true,
      },
      {
        path: `/${config.appName}/policy/priority`,
        component: PolicyPriorityView,
        exact: true,
      },
      {
        path: `/${config.appName}/policy/view/:policyId`,
        component: PolicyView,
        exact: false,
      },
      {
        path: `/${config.appName}/policy/edit/:policyId`,
        component: EditSelectedPolicy,
        exact: true,
      },
      {
        path: `/${config.appName}/policy/duplicate/:policyId`,
        component: DuplicateSelectedPolicy,
        exact: true,
      },
      {
        path: `/${config.appName}/roles`,
        component: Roles,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics`,
        component: Analytics,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/applications`,
        component: Applications,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-assignment`,
        component: DeviceAssignment,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity`,
        component: DeviceConnectivity,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/mobile-connectivity`,
        component: MobileConnectivity,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/wifi-connectivity`,
        component: WifiConnectivity,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/network-type-overtime`,
        component: NetworkTypeOverTime,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/signal-strength`,
        component: SignalStrength,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/signal-strength-by-device`,
        component: SignalStrengthByDevice,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/sim-change`,
        component: SimChange,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-connectivity/sim-change-by-device`,
        component: SimChangeByDevice,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-firmware`,
        component: DeviceFirmware,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-hardware`,
        component: DeviceHardware,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/device-inventory`,
        component: DeviceInventory,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/operations`,
        component: Operations,
        exact: true,
      },
      {
        path: `/${config.appName}/analytics/policy`,
        component: Policy,
        exact: true,
      },

      {
        path: `/${config.appName}/analytics/security`,
        component: Security,
        exact: true,
      },

      // {
      //   path: `/${config.appName}/devicetypes`,
      //   component: DeviceTypes,
      //   exact: true,
      // },
      {
        path: `/${config.appName}/configurations/platform-configuration`,
        component: PlatformConfiguration,
        exact: false,
        routes: [
          {
            path: `/${config.appName}/configurations/platform-configuration/general-config`,
            component: GeneralConfiguration,
            name: 'label_generalConfigs',
            scope: ['perm:view-configuration'],
            exact: false,
            routes: [
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/general`,
                component: General,
                name: 'label_General',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/theme`,
                component: Theme,
                name: 'label_theme',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/enrollment`,
                component: Enrollment,
                name: 'label_Enrolment',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/add-server`,
                component: AddServer,
                name: 'label_FTPServer',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/location-update`,
                component: LocationPublisher,
                name: 'label_LocationUpdate',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/billing`,
                component: BillingConfig,
                name: 'label_Billing',
                scope: ['perm:admin:tenant:view'],
                exact: false,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/general-config/evaluate`,
                component: Evaluate,
                name: 'label_Evaluate_Tenant',
                scope: ['perm:admin:tenant:view'],
                exact: false,
              },
            ],
          },
          {
            path: `/${config.appName}/configurations/platform-configuration/android`,
            component: AndroidConfiguration,
            name: 'label_androidConfigs',
            scope: ['perm:android:view-configuration'],
            exact: false,
            routes: [
              {
                path: `/${config.appName}/configurations/platform-configuration/android/android-general-configurations`,
                component: AndroidGeneralConfigurations,
                name: 'label_General',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/android/policy-config`,
                component: PolicyConfiguration,
                name: 'home_menu_policy',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/android/kiosk-provisioning`,
                component: KioskProvisioning,
                name: 'label_kiosk',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/android/android-for-work`,
                component: AndroidForWork,
                name: 'label_AndroidForWork',
                exact: true,
              },
            ],
          },
          {
            path: `/${config.appName}/configurations/platform-configuration/ios`,
            component: IOSConfiguration,
            name: 'label_iOSConfig',
            scope: ['perm:ios:view-configuration'],
            exact: false,
            routes: [
              {
                path: `/${config.appName}/configurations/platform-configuration/ios/mdm-profile`,
                component: MDMProfile,
                name: 'label_MDMProfile',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/ios/ios-eula`,
                component: iOSEULA,
                name: 'label_EULA',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/ios/apns`,
                component: APNSConfig,
                name: 'label_APNS',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/ios/dep`,
                component: DEPConfig,
                name: 'label_DEP',
                exact: true,
              },
            ],
          },
          {
            path: `/${config.appName}/configurations/platform-configuration/windows`,
            component: WindowsConfiguration,
            name: 'label_windowsConfigs',
            scope: ['perm:windows:view-configuration'],
            exact: false,
            routes: [
              {
                path: `/${config.appName}/configurations/platform-configuration/windows/general-config`,
                component: GeneralConfig,
                name: 'label_generalConfigs',
                exact: true,
              },
              {
                path: `/${config.appName}/configurations/platform-configuration/windows/agent-config`,
                component: AgentConfig,
                name: 'label_agentConfiguration',
                exact: true,
              },
            ],
          },
          {
            path: `/${config.appName}/configurations/platform-configuration/alerting-config`,
            component: AlertingConfiguration,
            name: 'label_alertingConfigs',
            scope: ['perm:view-configuration'],
            exact: false,
            routes: [
              {
                path: `/${config.appName}/configurations/platform-configuration/alerting-config/geo`,
                component: GeoAlerts,
                name: 'label_GeoAlerts',
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: `/${config.appName}/configurations/certificates`,
        component: Certificates,
        exact: true,
      },
      {
        path: `/${config.appName}/notifications`,
        component: Notifications,
        exact: true,
      },
      {
        path: `/${config.appName}/device-locations`,
        component: DeviceLocations,
        exact: true,
      },
      {
        path: `/${config.appName}/geo-fences`,
        component: GeoFences,
        exact: true,
        permissions: [
          '/permission/admin/device-mgt/devices/owning-device/manage-geo-fence',
        ],
      },
      {
        path: `/${config.appName}/devices/:deviceType/:deviceIdentifier`,
        component: AtlasSingleDevice,
        exact: false,
      },
      {
        path: `/${config.appName}/operators/:OPERATOR_ID`,
        component: AtlasSingleOperator,
        exact: false,
      },
      {
        path: `/${config.appName}/products/:PRODUCT_ID`,
        component: AtlasSingleProduct,
        exact: false,
      },
      {
        path: `/${config.appName}/virus/history/:deviceType/:deviceIdentifier`,
        component: VirusHistory,
        exact: true,
      },
    ],
  },
];

ReactDOM.render(<App routes={routes} />, document.getElementById('root'));

// If you want your app e and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
