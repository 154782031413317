/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  ZoomInOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Menu,
  PageHeader,
  Button,
  Typography,
  Row,
  Col,
  Collapse,
  Tooltip,
  Result,
  Alert,
} from 'antd';
import DeviceTable from '../../components/DevicesTable';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import AdvancedSearch from '../../components/DevicesTable/components/AdvancedSearch';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import Filter from '../../components/Filter';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import NoAccessDescriptionModal from '../../components/NoAccessDescriptionModal';
import DeviceListWithSignalStrength from '../../../../../public/images/device_List.png';
import SignalStrength from '../../../../../public/images/DeviceListwithSignalStrength.png';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

let setSearchFields = t => {
  return [
    {
      name: 'name',
      type: 'input',
      placeholder: t('label_deviceName'),
      label: t('label_name'),
    },
    {
      name: 'user',
      type: 'input',
      placeholder: t('label_owner'),
      label: t('home_menu_user'),
    },
    {
      name: 'serialNumber',
      type: 'input',
      placeholder: t('label_serialNumber'),
      label: t('label_serialNumber'),
    },
    {
      name: 'status',
      placeholder: t('label_status'),
      type: 'select',
      values: ['All'],
      label: t('label_status'),
    },
    {
      name: 'type',
      placeholder: t('label_machineType'),
      type: 'select',
      values: ['All'],
      label: t('label_type'),
    },
    {
      name: 'ownership',
      placeholder: t('label_ownership'),
      type: 'select',
      values: ['All'],
      label: t('label_ownership'),
    },
  ];
};

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_machines'),
    },
  ];
};

const emptyDevicePage = t => {
  return {
    title: t('noMachine_Txt'),
    subTitle: t('enrollNewMachine_Txt'),
    buttonName: t('label_enrollNewMachine'),
    url: '/devices/enroll',
  };
};

class Devices extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    this.state = {
      filters: {},
      filterFields: [],
      advancedSearchData: {
        conditions: [],
      },
      loadSearchBar: false,
      showAdvancedSearch: false,
      advancedItem: {
        isViewNoAccessModal: false,
      },
    };
    const items = [
      this.getItem(t('label_machinesByWiFiSignal'), 'wifi'),
      this.getItem(t('label_machinesByMobileSignal'), 'mobile'),
      this.getItem(t('label_signalStrengthByMachines'), 'signal_strength'),
    ];
    this.menu = (
      <Menu onClick={e => this.handleAdvancedMenuClick(e)} items={items} />
    );
  }

  componentDidMount() {
    this.context.setCurrentRoute('allDevicesPenAssembly');
    this.getDeviceFilters();
  }

  getPayload = payload => {
    this.setState({ advancedSearchData: payload });
  };

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  handleAdvancedMenuClick = event => {
    const menuKey = event.key;
    const { t } = this.props;
    let title;
    let content;
    let imgSrc;
    let redirectPage;

    switch (menuKey) {
      case 'wifi':
        redirectPage =
          window.location.origin +
          `/${this.config.appName}/analytics/device-connectivity/wifi-connectivity?operator=All&panelId=2`;
        title = t('label_machinesByWiFiSignal');
        imgSrc = DeviceListWithSignalStrength;
        content = (
          <div>
            <Alert
              message={t('analytic_warningTxt1', {
                label: t('label_machinesByWiFiSignal'),
              })}
              type="warning"
            />
          </div>
        );
        break;
      case 'mobile':
        redirectPage =
          window.location.origin +
          `/${this.config.appName}/analytics/device-connectivity/mobile-connectivity?operator=All&panelId=2`;
        title = t('label_machinesByMobileSignal');
        imgSrc = DeviceListWithSignalStrength;
        content = (
          <div>
            <Alert
              message={t('analytic_warningTxt1', {
                label: t('label_machinesByMobileSignal'),
              })}
              type="warning"
            />
          </div>
        );
        break;
      case 'signal_strength':
        redirectPage =
          window.location.origin +
          `/${this.config.appName}/analytics/device-connectivity/signal-strength?operator=All&panelId=2`;
        title = t('label_signalStrengthByMachines');
        imgSrc = SignalStrength;
        content = (
          <div>
            <Alert
              message={t('analytic_warningTxt2', {
                label: t('label_signalStrengthByMachines'),
              })}
              type="warning"
            />
          </div>
        );
        break;
    }

    if (this.config.analyticEnabledDomains.includes(this.config.userDomain)) {
      window.open(redirectPage, '_blank');
    } else {
      this.setState({
        advancedItem: {
          title: title,
          imgSrc: imgSrc,
          content: content,
          isViewNoAccessModal: true,
        },
      });
    }
  };

  onCloseAdvancedNoAccessModal = () => {
    this.setState({
      advancedItem: {
        isViewNoAccessModal: false,
      },
    });
  };

  getDeviceFilters = () => {
    const config = this.props.context;
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/devices/filters',
      )
      .then(res => {
        if (res.status === 200) {
          const deviceFilters = res.data.data;
          let searchFields = setSearchFields(t);
          searchFields.map(searchField => {
            if (searchField.name === 'status') {
              searchField.values.push(...deviceFilters.statuses);
            }
            if (searchField.name === 'type') {
              searchField.values.push(...deviceFilters.deviceTypes);
            }
            if (searchField.name === 'ownership') {
              searchField.values.push(...deviceFilters.ownerships);
            }
          });
          this.setState({ filterFields: searchFields });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  fetchSearchBarStatus = status => {
    this.setState({ loadSearchBar: status });
  };

  onChangeCollapse = e => {
    if (e[0]) {
      this.setState({ showAdvancedSearch: true });
    } else {
      this.setState({ showAdvancedSearch: false });
    }
  };

  toggleAdvancedSearch = status => {
    this.setState({ showAdvancedSearch: status });
  };

  render() {
    const config = this.props.context;
    const { t } = this.props;
    const {
      filters,
      filterFields,
      showAdvancedSearch,
      loadSearchBar,
      advancedItem,
    } = this.state;
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      config.serverConfig.invoker.deviceMgt +
      '/devices?';
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/devices/owning-device/view'}
        />
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              >
              </PageHeader>
              {/* <div className={styles.table}>
                {loadSearchBar && (
                  <Collapse
                    bordered={false}
                    expandIcon={() => <SearchOutlined />}
                    className={styles.searchCollapse}
                  >
                    <Panel
                      header={
                        showAdvancedSearch
                          ? t('label_advancedSearch')
                          : t('form_searchMachines')
                      }
                      key="1"
                      style={{ borderColor: '#fff' }}
                    >
                      <Row>
                        <Col span={22}>
                          {!showAdvancedSearch && (
                            <Filter
                              filters={filterFields}
                              callback={(params, values) =>
                                this.fetchFilterData({}, values)
                              }
                            />
                          )}
                          {showAdvancedSearch && (
                            <AdvancedSearch
                              callback={payload => this.getPayload(payload)}
                            />
                          )}
                        </Col>
                        <div style={{ float: 'right', paddingLeft: '5px' }}>
                          <Tooltip
                            title={
                              !showAdvancedSearch
                                ? t('label_advancedSearch')
                                : t('label_close')
                            }
                          >
                            <Button
                              type="primary"
                              icon={
                                !showAdvancedSearch ? (
                                  <ZoomInOutlined />
                                ) : (
                                  <CloseOutlined />
                                )
                              }
                              size={'large'}
                              onClick={() => {
                                if (!showAdvancedSearch) {
                                  this.toggleAdvancedSearch(true);
                                } else {
                                  this.toggleAdvancedSearch(false);
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </Row>
                    </Panel>
                  </Collapse>
                )}
              </div> */}
              <div className={styles.table}>
                
              </div>
              <div className={styles.deviceTable}>
                <DeviceTable
                  apiUrl={apiUrl}
                  filters={filters}
                  advancedSearchData={this.state.advancedSearchData}
                  showAdvancedSearch={showAdvancedSearch}
                  searchBarCallback={status =>
                    this.fetchSearchBarStatus(status)
                  }
                  emptyDevicePage={emptyDevicePage(t)}
                />
              </div>
              {!this.config.analyticEnabledDomains.includes(
                this.config.userDomain,
              ) &&
                advancedItem.isViewNoAccessModal && (
                  <div>
                    <NoAccessDescriptionModal
                      title={advancedItem.title}
                      content={advancedItem.content}
                      imgSrc={advancedItem.imgSrc}
                      isModalView={advancedItem.isViewNoAccessModal}
                      onCloseAdvancedNoAccessModal={
                        this.onCloseAdvancedNoAccessModal
                      }
                    />
                  </div>
                )}
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

Devices.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Devices));
