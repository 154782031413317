/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { Component, Fragment } from 'react';
import {
  Map,
  TileLayer,
  Marker,
  Tooltip,
  Popup,
  CircleMarker,
  Polygon,
  Circle,
} from 'react-leaflet';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import axios from 'axios';
import { Alert, Row, Typography } from 'antd';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import L from 'leaflet';
import { getRandomColor } from '../../../../../../services/utils/commonHandler';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Title, Text } = Typography;

// Icons for the location markers
const geoFenceMarker = new L.Icon({
  iconUrl: require('./geo-fence.svg'),
  iconRetinaUrl: require('./geo-fence.svg'),
  shadowAnchor: [22, 22],
  popupAnchor: [0, -22],
  iconSize: [50, 50],
  tooltipAnchor: [0, -22],
});

class DeviceLocationMap extends Component {
  defaultZoomLevel;
  defaultBound = {
    southWest: {
      lat: -39.09596293630548,
      lng: -151.171875,
    },
    northEast: {
      lat: 38.95940879245423,
      lng: 151.34765625,
    },
  };
  defaultPosition;
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.customMap = React.createRef();
    this.state = {
      locations: [],
      popupBody: null,
      zoomLevel: 2,
      geoFences: [],
    };
  }

  componentDidMount() {
    this.setDeviceLocations(
      this.defaultBound.southWest.lat,
      this.defaultBound.northEast.lat,
      this.defaultBound.southWest.lng,
      this.defaultBound.northEast.lng,
      this.config.geoMap.deviceLocation.defaultZoomLevel,
    );
    this.defaultZoomLevel = this.config.geoMap.deviceLocation.defaultZoomLevel;
    this.defaultPosition = this.config.geoMap.deviceLocation.defaultCenter;

    if (isAuthorized(this.config.scopes, ['perm:geo-service:geo-fence'])) {
      this.fetchGeoFenceData();
    }
  }

  // get Geo fence data
  fetchGeoFenceData = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/geo-services/geo-fence',
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          let geoFences = res.data.data.data;
          geoFences.map((fence, i) => {
            fence.key = i;
          });
          this.setState({
            geoFences,
            loading: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_loadGeoFenceDataError'), t);
        this.setState({ loading: false });
      });
  };

  // set device Location from using bounds and zoom level
  setDeviceLocations = (
    minLatitute,
    maxLatitute,
    minLongtitute,
    maxLongtitute,
    zoomLevel,
  ) => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/geo-services/stats' +
          '/device-locations?' +
          'minLat=' +
          minLatitute +
          '&maxLat=' +
          maxLatitute +
          '&minLong=' +
          minLongtitute +
          '&maxLong=' +
          maxLongtitute +
          '&zoom=' +
          zoomLevel,
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            locations: res.data.data,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_fetchLocationsError'), t);
      });
  };

  onClickMarker = (e, latitude, longitude) => {
    let zoomLevel = this.customMap.current.leafletElement.getZoom();
    if (zoomLevel + 9 <= 19) {
      zoomLevel = zoomLevel + 9;
    } else {
      zoomLevel = 19;
    }
    this.customMap.current.leafletElement.setZoomAround(
      [latitude, longitude],
      zoomLevel,
    );
  };

  // set Markers
  setMarkers = deviceLocations => {
    const { popupBody } = this.state;
    let markers = [];
    deviceLocations.map(location => {
      if (location.count === 1) {
        markers.push(
          <Marker
            key={location.deviceIdentification}
            position={[
              location.coordinates.latitude,
              location.coordinates.longitude,
            ]}
            onMouseOver={e =>
              this.onHandleMouseOver(
                e,
                location.deviceType,
                location.deviceIdentification,
              )
            }
            onMouseOut={e => {
              e.target.closePopup();
            }}
            onClick={e =>
              this.onClickMarker(
                e,
                location.coordinates.latitude,
                location.coordinates.longitude,
              )
            }
            onDblclick={e =>
              this.onClickMarker(
                e,
                location.coordinates.latitude,
                location.coordinates.longitude,
              )
            }
          >
            <Popup closeButton={false}>
              {popupBody !== null ? <div>{popupBody}</div> : null}
            </Popup>
            <Tooltip
              direction="center"
              permanent={true}
              interactive={true}
              className={styles.tooltip}
            >
              <Link
                to={`/${this.config.appName}/devices/${location.deviceType}/${location.deviceIdentification}/info`}
              >
                <p>{location.deviceName}</p>
              </Link>
            </Tooltip>
          </Marker>,
        );
      } else {
        let color;
        if (location.count <= 10) {
          color = '#4df82a';
        } else if (location.count <= 100) {
          color = '#f9ff3f';
        } else if (location.count <= 1000) {
          color = '#ff9c3f';
        } else if (location.count <= 10000) {
          color = '#ff5e40';
        }
        markers.push(
          <CircleMarker
            key={location.deviceIdentification}
            center={[
              location.coordinates.latitude,
              location.coordinates.longitude,
            ]}
            radius={15}
            fillOpacity={1}
            opacity={0.5}
            fillColor={color}
            color={color}
            weight={10}
            stroke={true}
            onClick={e =>
              this.onClickMarker(
                e,
                location.coordinates.latitude,
                location.coordinates.longitude,
              )
            }
            onDblclick={e =>
              this.onClickMarker(
                e,
                location.coordinates.latitude,
                location.coordinates.longitude,
              )
            }
          >
            <Tooltip
              permanent={true}
              direction={'center'}
              className={styles.customTooltip}
            >
              {location.count}
            </Tooltip>
          </CircleMarker>,
        );
      }
    });
    return (
      <div>
        {markers.map(marker => {
          return marker;
        })}
      </div>
    );
  };

  // get popup for a single device
  onHandleMouseOver = (e, deviceType, deviceIdentification) => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          `/devices/${deviceType}/${deviceIdentification}`,
      )
      .then(res => {
        if (res.status === 200) {
          const deviceData = res.data.data;
          const popupBody = (
            <div>
              <Title level={4}>{deviceData.name}</Title>
              <Row>
                <Text strong> {t('label_type')} : </Text>
                <Text>{deviceData.type}</Text>
              </Row>
              <Row>
                <Text strong> {t('label_status')} : </Text>
                <Text>{deviceData.enrolmentInfo.status}</Text>
              </Row>
              <Row>
                <Text strong> {t('label_owner')} : </Text>
                <Text>{deviceData.enrolmentInfo.owner}</Text>
              </Row>
            </div>
          );
          this.setState({
            popupBody: popupBody,
          });
          e.target.openPopup();
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceDetailsError'), t);
      });
  };

  setGeoFences = fences => {
    let markers = [];
    fences.map(fence => {
      let color = getRandomColor();
      let marker;
      if (fence.fenceShape === 'polygon') {
        marker = (
          <Polygon
            key={fence.id}
            positions={JSON.parse(fence.geoJson)}
            color={color}
            fillOpacity={0.5}
          >
            <Tooltip direction="center">
              <Text className={styles.tooltipText}>{fence.fenceName}</Text>
            </Tooltip>
          </Polygon>
        );
      } else {
        marker = (
          <Circle
            key={fence.id}
            center={{ lat: fence.latitude, lng: fence.longitude }}
            color={color}
            fillOpacity={0.5}
            radius={fence.radius}
          >
            {' '}
            <Marker
              position={{ lat: fence.latitude, lng: fence.longitude }}
              icon={geoFenceMarker}
            >
              <Tooltip direction="center">
                <Text className={styles.tooltipText}>{fence.fenceName}</Text>
              </Tooltip>
            </Marker>
            <Tooltip direction="center">
              <Text className={styles.tooltipText}>{fence.fenceName}</Text>
            </Tooltip>
          </Circle>
        );
      }
      markers.push(marker);
    });

    return (
      <div>
        {markers.map(marker => {
          return marker;
        })}
      </div>
    );
  };

  // handle map movements
  onHandleMovements = () => {
    const latLngBound = this.customMap.current.leafletElement.getBounds();
    const zoomLevel = this.customMap.current.leafletElement.getZoom();
    const minLatitude = latLngBound.getSouthWest().lat;
    const maxLatitude = latLngBound.getNorthEast().lat;
    const minLongitude = latLngBound.getSouthWest().lng;
    const maxLongitude = latLngBound.getNorthEast().lng;
    this.setDeviceLocations(
      minLatitude,
      maxLatitude,
      minLongitude,
      maxLongitude,
      zoomLevel,
    );
  };

  render() {
    const attribution = this.config.geoMap.attribution;
    const url = this.config.geoMap.url;
    const { t } = this.props;
    return (
      <div style={{ borderRadius: 5, margin: 10 }}>
        {!isAuthorized(this.config.scopes, ['perm:geo-service:geo-fence']) && (
          <div>
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/devices/owning-device/manage-geo-fence'
              }
            />
            <Alert
              message={t('noPerm_manageGeoFences')}
              banner
              style={{ marginBottom: 15 }}
            />
          </div>
        )}
        <Map
          ref={this.customMap}
          center={this.defaultPosition}
          zoom={this.defaultZoomLevel}
          onMoveEnd={this.onHandleMovements}
          minZoom={2}
          maxZoom={19}
          animate={true}
          className={styles.locationMap}
        >
          <TileLayer url={url} attribution={attribution} />
          {this.setMarkers(this.state.locations)}
          {this.props.viewType === 'geoFenceVisible' && (
            <Fragment>{this.setGeoFences(this.state.geoFences)}</Fragment>
          )}
        </Map>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DeviceLocationMap));
