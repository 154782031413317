/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import DateCountdown from 'react-date-countdown-timer';
import { withTranslation } from 'react-i18next';

class QRExpTime extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      expireTime: 3600,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.payload !== this.props.payload) {
      const expireTime = this.props.payload['android.app.extra.token.expiry'];
      this.setState({ expireTime: expireTime });
    }
  }

  render() {
    const { t } = this.props;
    const fromTime = new Date().getTime();
    this.fromDate = new Date(fromTime);
    const tmpDate = new Date(fromTime);
    const toTime = tmpDate.setSeconds(
      tmpDate.getSeconds() + this.state.expireTime,
    );
    this.toDate = new Date(toTime);
    return (
      <div>
        {t('qrCodeExpireDate_Txt')}
        <DateCountdown
          dateFrom={this.fromDate.toString()}
          dateTo={this.toDate.toString()}
        />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(QRExpTime));
