/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Select,
  Row,
  Form,
  Input,
  Card,
  Space,
  Col,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

class FilterNew extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedItemObjectMap: {},
      selectableItems: [],
      selectedItems: {},
      searchEnabled: false,
      enableClear: false,
      labelNameMap: {},
    };
  }

  onFinish = values => {
    let payload = {};
    values.filters.forEach(value => {
      payload[this.state.labelNameMap[value.filter]] = value.value;
    });
    this.props.callback({}, payload);
  };

  componentDidMount() {
    let labelNameMap = { ...this.state.labelNameMap };
    this.props.filters.forEach(filter => {
      labelNameMap[filter.label] = filter.name;
    });
    this.setState({ labelNameMap });
  }

  modifyFilters = () => {
    let selectableItems = [...this.state.selectableItems];
    const { selectedItems } = this.state;

    selectableItems.forEach((item, index) => {
      if (Object.values(selectedItems).includes(item.name)) {
        selectableItems.splice(index, 1);
      }
    });

    if (this.state.selectableItems.length === 0) {
      this.setState({
        selectableItems: this.props.filters,
        enableClear: true,
      });
    } else {
      this.setState({
        selectableItems: selectableItems,
        enableClear: true,
      });
    }
  };

  /**
   * State variable definitions
   * selectableItems : Selectable options of filter selector.
   * selectedItems : Selected value (String) from filter selector is mapped to the respective key.
   * selectedItemObjectMap : Object from filters prop array which belongs to the selected value from
   *                filter selector is mapped to the respective key.
   *
   */

  onSelectValue = (key, value) => {
    this.props.filters.forEach(filter => {
      if (filter.label === value) {
        value = filter.name;
      }
    });

    let selectableItems = [...this.state.selectableItems];
    let selectedItems = { ...this.state.selectedItems };

    // Push respective object from filters array to selectableItems array when a filter is selected.
    if (selectedItems !== {}) {
      this.props.filters.forEach(filter => {
        if (filter.name === selectedItems[key]) {
          selectableItems.push(filter);
        }
      });
    }

    // Remove the respective object from selectableItems list
    selectableItems.forEach((item, index) => {
      if (item.name === value) {
        selectableItems.splice(index, 1);
      }
    });

    // Map selectedItems
    selectedItems[key] = value;

    // Map selectedItem object
    let selectedItemObjectMap = { ...this.state.selectedItemObjectMap };
    this.props.filters.forEach(filter => {
      if (filter.name === value) {
        selectedItemObjectMap[key] = filter;
      }
    });

    let searchEnabled = Object.keys(selectedItems).length > 0;

    this.setState({
      selectedItems,
      selectableItems,
      selectedItemObjectMap,
      searchEnabled,
    });
  };

  onRemoveItem = key => {
    let selectedItems = { ...this.state.selectedItems };
    let selectableItems = [...this.state.selectableItems];

    // Add the selected filter of the removing tile to selectableItems list.
    this.props.filters.forEach(filter => {
      if (filter.name === selectedItems[key]) {
        selectableItems.push(filter);
      }
    });
    // Delete the selected filter of the removing tile from selectedItems list.
    delete selectedItems[key];

    let searchEnabled = Object.keys(selectedItems).length > 0;

    this.setState({
      selectedItems,
      selectableItems,
      searchEnabled,
      enableClear: searchEnabled,
    });
  };

  clearForm = () => {
    this.formRef.current.resetFields();
    this.props.callback({}, {});
    this.setState({
      selectableItems: this.props.filters,
      selectedItems: {},
      selectedItemObjectMap: {},
      selectedItemenableClear: false,
      searchEnabled: false,
    });
  };

  render() {
    const { t } = this.props;
    const {
      selectedItemObjectMap,
      selectableItems,
      searchEnabled,
      enableClear,
    } = this.state;
    const formItemMargin = -2;

    return (
      <Form
        name="dynamic_form_nest_item"
        onFinish={this.onFinish}
        autoComplete="off"
        ref={this.formRef}
      >
        <Row>
          <Col span={22}>
            <div>
              <Form.List name={'filters'}>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      <Row>
                        {fields.map(field => (
                          <Card
                            key={field.key}
                            bodyStyle={{ padding: 3 }}
                            style={{
                              borderRadius: 10,
                              marginLeft: '3px',
                              marginBottom: '3px',
                            }}
                            hoverable
                          >
                            <Space size={'small'}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'filter']}
                                fieldKey={[field.fieldKey, 'filter']}
                                rules={[
                                  {
                                    required: true,
                                    message: t('label_filter_required'),
                                  },
                                ]}
                                style={{ marginBottom: formItemMargin }}
                              >
                                <Select
                                  autoFocus
                                  defaultOpen={true}
                                  size={'small'}
                                  bordered={false}
                                  placeholder={t('label_select_folder')}
                                  onSelect={value =>
                                    this.onSelectValue(field.key, value)
                                  }
                                  dropdownMatchSelectWidth={false}
                                >
                                  {selectableItems.map(filter => {
                                    return (
                                      <Option
                                        key={filter.name}
                                        value={filter.label}
                                      >
                                        {filter.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              {selectedItemObjectMap[field.key] &&
                                selectedItemObjectMap[field.key].type ===
                                  'select' && (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('label_value_required'),
                                      },
                                    ]}
                                    style={{ marginBottom: formItemMargin }}
                                  >
                                    <Select
                                      autoFocus
                                      defaultOpen={false}
                                      size={'small'}
                                      bordered={false}
                                      placeholder={
                                        `${t('label_select')} ` +
                                        selectedItemObjectMap[field.key]
                                          .placeholder
                                      }
                                      dropdownMatchSelectWidth={false}
                                    >
                                      {selectedItemObjectMap[
                                        field.key
                                      ].values.map((value, i) => {
                                        return (
                                          <Option key={i} value={value}>
                                            {value}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                )}
                              {selectedItemObjectMap[field.key] &&
                                selectedItemObjectMap[field.key].type ===
                                  'calender' && (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('label_value_required'),
                                      },
                                    ]}
                                    style={{ marginBottom: formItemMargin }}
                                  >
                                    <RangePicker
                                      renderExtraFooter={() => 'extra footer'}
                                      autoFocus
                                      format="YYYY-MM-DD"
                                      disabledDate={current =>
                                        current &&
                                        current >
                                          moment(new Date(), 'YYYY-MM-DD')
                                      }
                                      placeholderText={t('label_select_date')}
                                    />
                                  </Form.Item>
                                )}
                              {selectedItemObjectMap[field.key] &&
                                selectedItemObjectMap[field.key].type ===
                                  'multipleSelect' && (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('label_value_required'),
                                      },
                                    ]}
                                    style={{
                                      marginBottom: formItemMargin,
                                      minWidth: 170,
                                    }}
                                  >
                                    <Select
                                      autoFocus
                                      mode="multiple"
                                      defaultOpen={false}
                                      size={'small'}
                                      bordered={false}
                                      placeholder={
                                        `${t('label_select')} ` +
                                        selectedItemObjectMap[field.key]
                                          .placeholder
                                      }
                                      dropdownMatchSelectWidth={false}
                                    >
                                      {selectedItemObjectMap[
                                        field.key
                                      ].values.map((value, i) => {
                                        return (
                                          <Option key={i} value={value}>
                                            {value}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                )}
                              {selectedItemObjectMap[field.key] &&
                                selectedItemObjectMap[field.key].type ===
                                  'input' && (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('label_value_required'),
                                      },
                                    ]}
                                    style={{ marginBottom: formItemMargin }}
                                  >
                                    <Input
                                      autoFocus
                                      size={'small'}
                                      style={{ border: 'none' }}
                                      placeholder={
                                        `${t('label_input')} ` +
                                        selectedItemObjectMap[field.key]
                                          .placeholder
                                      }
                                    />
                                  </Form.Item>
                                )}
                              <Form.Item
                                fieldKey={[field.fieldKey, 'remove']}
                                style={{ marginBottom: formItemMargin }}
                              >
                                <CloseOutlined
                                  onClick={() => {
                                    remove(field.name);
                                    this.onRemoveItem(field.key);
                                    if (fields.length === 1) {
                                      this.props.callback({}, {});
                                    }
                                  }}
                                  style={{ marginLeft: 3, marginRight: 10 }}
                                />
                              </Form.Item>
                            </Space>
                          </Card>
                        ))}
                        <Form.Item style={{ marginBottom: -8 }}>
                          <Tooltip title={t('label_add_tag')}>
                            <Button
                              disabled={
                                !(
                                  fields.length + 1 <=
                                  this.props.filters.length
                                )
                              }
                              type="dashed"
                              size={'large'}
                              icon={<PlusOutlined />}
                              style={{
                                marginLeft: '5px',
                                marginBottom: '9px',
                              }}
                              onClick={() => {
                                add();
                                this.modifyFilters();
                              }}
                            />
                          </Tooltip>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: -8 }}>
                          <Tooltip title={t('label_search')}>
                            <Button
                              disabled={!searchEnabled}
                              htmlType={'submit'}
                              type="primary"
                              icon={<SearchOutlined />}
                              size={'large'}
                              style={{
                                marginLeft: '5px',
                              }}
                            />
                          </Tooltip>
                        </Form.Item>
                      </Row>
                    </div>
                  );
                }}
              </Form.List>
            </div>
          </Col>

          <Col span={2}>
            <div style={{ float: 'right' }}>
              <Space>
                <Form.Item style={{ marginBottom: -8 }}>
                  <Tooltip title={t('label_clear_tag')}>
                    <Button
                      disabled={!enableClear}
                      type="dashed"
                      danger
                      icon={<ClearOutlined />}
                      size={'large'}
                      style={{ borderRadius: 10, marginLeft: '3px' }}
                      onClick={this.clearForm}
                    />
                  </Tooltip>
                </Form.Item>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default withTranslation()(FilterNew);
