/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Tooltip,
} from 'antd';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import { QuestionCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const wifiConfigKeySuffix = '_carbon.super_WiFi_Configuration';

class WiFiConfiguration extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      loading: false,
    };
  }

  onCancelHandler = () => {
    let wifConfig = null;
    if (this.props.wifiConfig) {
      wifConfig = this.props.wifiConfig;
    }
    this.props.callback(false, wifConfig);
  };

  onFinish = values => {
    if (this.props.wifiConfig === null) {
      this.addWifiConfigs(values);
    } else {
      this.updateWifiConfigs(values);
    }
  };

  getWifiConfigMetaKey = () => {
    return this.config.user.includes('@')
      ? this.config.user.replace('@', '_') + '_WiFi_Configuration'
      : this.config.user + wifiConfigKeySuffix;
  };

  addWifiConfigs = values => {
    const metaKey = this.getWifiConfigMetaKey();
    const { t } = this.props;
    const requestPayload = {
      dataType: 'STRING',
      metaKey: metaKey,
      metaValue: JSON.stringify(values),
    };
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0/metadata/';
    axios
      .post(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 201) {
          notification.success({
            message: t('api_successMsg'),
            description: t('api_saveWifiConfigMsg'),
          });
          this.props.callback(false, requestPayload);
        }
      })
      .catch(error => {
        handleApiError(error, t('api_saveWifiConfigError'), t);
        this.setState({ loading: false });
      });
  };

  updateWifiConfigs = values => {
    const metaKey = this.getWifiConfigMetaKey();
    const { t } = this.props;
    const requestPayload = {
      dataType: 'STRING',
      metaKey: metaKey,
      metaValue: JSON.stringify(values),
    };
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            description: t('api_updateWifiPlatformConfigMsg'),
          });
          this.props.callback(false, requestPayload);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateConfigError', { label: t('label_wifiPlatformConfigs') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={t('title_wifiConfig')}
        open={this.props.viewWiFiConfig}
        onCancel={this.onCancelHandler}
        footer={null}
        maskClosable={false}
      >
        <Form
          {...formItemLayout}
          name="AndroidKioskProvisioningForm"
          onFinish={this.onFinish}
          ref={this.formRef}
          initialValues={this.props.wifiConfig}
        >
          <Form.Item
            label={
              <span>
                {t('label_wifiSSID')}&nbsp;
                <Tooltip title={t('wifiSSID_infoTxt')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="android.app.extra.PROVISIONING_WIFI_SSID"
            help={t('wifiSSID_helpTxt')}
            rules={[
              {
                required: true,
                message: t('form_wifiSSIDRequiredMsg'),
              },
            ]}
          >
            <Input placeholder={t('ex_Txt', { exampleTxt: 'Entgra' })} />
          </Form.Item>
          <br />
          <Form.Item
            label={
              <span>
                {t('label_wifiSecurityType')}&nbsp;
                <Tooltip title={t('wifiSecurityType_infoTxt')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE"
            rules={[
              {
                required: true,
                message: t('form_wifiSecurityTypeRequiredMsg'),
              },
            ]}
          >
            <Select placeholder={t('form_selectWifiSecurityType')}>
              <Option value="WPA">WPA/WPA2-psk</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t('label_wifiPassword')}&nbsp;
                <Tooltip title={t('wifiPassword_infoTxt')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="android.app.extra.PROVISIONING_WIFI_PASSWORD"
            rules={[
              {
                required: true,
                message: t('form_wifiPasswordRequiredMsg'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            {this.props.wifiConfig === null ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !isAuthorized(this.config.scopes, ['perm:metadata:create'])
                }
              >
                <HtmlComments
                  permission={'/permission/admin/device-mgt/metadata/create'}
                />
                {t('label_save')}
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !isAuthorized(this.config.scopes, ['perm:metadata:update'])
                }
              >
                <HtmlComments
                  permission={'/permission/admin/device-mgt/metadata/update'}
                />
                {t('label_update')}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default withConfigContext(withTranslation()(WiFiConfiguration));
