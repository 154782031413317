/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';

import {
  BellOutlined,
  BookOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  AimOutlined,
  SafetyCertificateOutlined,
  ControlOutlined,
  GoldOutlined,
  TeamOutlined,
  EditOutlined,
  LayoutOutlined,
  FieldTimeOutlined,
  CarryOutOutlined,
  RiseOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { Layout, Menu, Button, Avatar, notification, Tooltip } from 'antd';
import { Switch, Link } from 'react-router-dom';
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes';
import './styles.module.css';
import styles from './styles.module.css';
import { withConfigContext } from '../../components/ConfigContext';
import axios from 'axios';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import Navigator from './components/Navigator';
import ChangePassword from './components/ChangePassword';
import { handleApiError } from '../../services/utils/errorHandler';
import { isAuthorized } from '../../services/utils/authorizationHandler';
import { getUiConfig } from '../../services/utils/uiConfigHandler';
import { withTranslation } from 'react-i18next';
import LanguageChooser from '../../components/LocaleChooser';
import TooltipComponent from './components/TooltipComponent';
import { Footer } from 'antd/es/layout/layout';
import { randomizeImageUrlToAvoidLoadingFromCache } from '../../services/utils/urlUtils';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { archiveHubspotConversations } from '../../services/utils/hubspotChatHandler';

const { Header, Content, Sider } = Layout;

export const RoutesContext = React.createContext();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;

    this.state = {
      routes: props.routes,
      selectedKeys: [],
      deviceTypes: [],
      currentRoute: 'devices',
      isChangePasswordModalVisible: false,
      collapsed: false,
      tenantDomain: this.config.userDomain,
      run: true,
      stepIndex: 0,
    };
  }

  componentDidMount() {
    if (this.config.enableNotificationCheck) {
      this.interval = setInterval(() => {
        this.fetchData();
      }, this.config.notificationCheckInterval);
    }
    this.setState({
      isTrackerEnabled: false,
      trackerServer: null,
    });

    if (isAuthorized(this.config.scopes, ['perm:view-configuration'])) {
      this.getGeneralConfigs();
    }
    if (!localStorage.getItem('ui-tour')) {
      localStorage.setItem('ui-tour', 1);
      this.setState({ run: true });
    } else {
      this.setState({ run: false });
    }
  }

  getGeneralConfigs = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/configuration',
      )
      .then(res => {
        if (res.status === 200) {
          let responseData = res.data.data.configuration;
          if (responseData) {
            for (let platformConfig of [...responseData]) {
              if (platformConfig.name === 'locationPublishing') {
                let locationConfig = JSON.parse(platformConfig.value);
                this.setState({
                  isTrackerEnabled: locationConfig.isTrackerEnabled,
                  trackerServer: locationConfig.traccarServer,
                });
                break;
              }
            }
          }
          this.setState({ loading: true });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_generalConfigs') }),
          t,
        );
      });
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  // fetch data from api
  fetchData = () => {
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/notifications?status=NEW&offset=0&limit=1';

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const prevNotificationCookie = localStorage.getItem('notification');
          if (
            prevNotificationCookie &&
            prevNotificationCookie !== 'undefined'
          ) {
            const prevNotification = JSON.parse(prevNotificationCookie);
            if (
              prevNotification &&
              res.data.data.notifications.length > 0 &&
              prevNotification.id !== res.data.data.notifications[0].id &&
              res.data.data.notifications[0].description ===
                this.config.chatRequestDescription
            ) {
              notification.success({
                message: t('api_newNotificationMsg'),
                duration: 10,
                description: this.config.chatRequestDescription,
                btn: (
                  <Button onClick={this.onClickNotification}>
                    Go To Notifications
                  </Button>
                ),
              });
            }
          } else if (
            res.data.data.notifications.length > 0 &&
            res.data.data.notifications[0].description ===
              this.config.chatRequestDescription
          ) {
            notification.success({
              message: t('api_newNotificationMsg'),
              duration: 10,
              description: this.config.chatRequestDescription,
              btn: (
                <Button onClick={this.onClickNotification}>
                  Go To Notifications
                </Button>
              ),
            });
          }
          localStorage.setItem(
            'notification',
            JSON.stringify(res.data.data.notifications[0]),
          );
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_notifications') }),
          t,
        );
      });
  };

  onClickNotification = () => {
    window.location =
      window.location.origin +
      `/${this.config.serverConfig.platform}/notifications`;
  };

  setCurrentRoute = key => {
    this.setState({
      currentRoute: key,
    });
  };

  logout = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      if (uiConfig !== undefined) {
        let logoutUri;
        if (uiConfig.isSsoEnable) {
          logoutUri =
            window.location.origin +
            this.props.context.serverConfig.ssoLogoutUri;
        } else {
          logoutUri =
            window.location.origin + this.props.context.serverConfig.logoutUri;
        }
        axios
          .post(logoutUri)
          .then(res => {
            // if the api call status is correct then user will logout and then it goes to login page
            if (res.status === 200) {
              if (uiConfig.isSsoEnable) {
                window.location =
                  window.location.origin +
                  this.config.serverConfig.ssoLoginUri +
                  '?redirect=' +
                  window.location.origin +
                  `/${this.config.appName}`;
              } else {
                window.location =
                  window.location.origin + `/${this.config.appName}/login`;
              }
            }
          })
          .catch(function(error) {
            notification.error({
              message: t('api_errorMsg'),
              duration: 0,
              description: t('api_logoutError'),
            });
          });
      }
    });
  };

  trackerCon = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      let traccarUri = this.state.trackerServer + '/?token=';

      axios
        .get(
          window.location.origin +
            this.config.serverConfig.invoker.uri +
            '/traccar-mgt/v1.0/traccar/token',
        )
        .then(res => {
          if (res.status === 200) {
            this.setState({
              isModalVisible: false,
              loading: false,
            });
            notification.success({
              message: t('api_successMsg'),
              duration: 4,
              description: t('api_loadConfigMsg', {
                label: t('label_traccar'),
              }),
            });
            console.log(res);
            window.open(traccarUri + res.data.data);
          }
        })
        .catch(error => {
          handleApiError(
            error,
            t('api_loadError', { label: t('label_traccar') }),
            t,
          );
          this.setState({ loading: false });
        });
    });
  };

  onChangePassword = () => {
    this.setState({
      isChangePasswordModalVisible: true,
    });
  };

  onCancelChangedPassword = () => {
    this.setState({
      isChangePasswordModalVisible: false,
    });
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  sliderClick = item => {
    this.setState({ sliderSelectedTab: item.key });
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'app-publisher') {
      window.open(`/${this.config.publisherAppName}`);
    } else if (item.key === 'device-tracker') {
      this.trackerCon();
    } else if (item.key === 'allFactoryFlowLayoutsPenAssembly') {
      path = '/iot-device-mgt/factory/flowlayouts';
    } else if (item.key === 'allFactoryFlowlayoutsWeeklySummary') {
      path = '/iot-device-mgt/factory/weeklysummary';
    } else if (item.key === 'allOutputPenAssembly') {
      path = '/iot-device-mgt/output/penassembly';
    } else if (item.key === 'allBreakdowns') {
      path = '/iot-device-mgt/breakdowns';
    } else if (item.key === 'allEnergy') {
      path = '/iot-device-mgt/energy';
    } else if (item.key === 'allQuality') {
      path = '/iot-device-mgt/quality';
    } else if (item.key === 'allOperatorsPenAssembly') {
      path = '/iot-device-mgt/operators';
    } else if (item.key === 'allHourlyConsumption') {
      path = '/iot-device-mgt/consumption';
    } else if (item.key === 'allProductsPenAssembly') {
      path = '/iot-device-mgt/products';
    } else if (item.key === 'allDevicesPenAssembly') {
      path = '/iot-device-mgt/devices';
    } else if (item.key === 'deviceTypes') {
      path = '/iot-device-mgt/devices/types';
    } else if (item.key === 'certificates') {
      path = '/iot-device-mgt/configurations/certificates';
    } else if (item.key === 'platform-configuration') {
      path =
        '/iot-device-mgt/configurations/platform-configuration/general-config/general';
    } else if (
      item.key === 'policies' ||
      item.key === 'geo-fences' ||
      item.key === 'device-locations' ||
      item.key === 'users' ||
      item.key === 'roles' ||
      item.key === 'groups' ||
      item.key === 'dashboard' ||
      item.key === 'analytics'
    ) {
      path = '/iot-device-mgt/' + item.key;
    } else {
      path = '/iot-device-mgt/devices/' + item.key;
    }

    this.props.history.push(path);
  };

  headerClick = item => {
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'user') {
      path = `/${this.config.appName}/users?add-new-user=true`;
    } else if (item.key === 'deviceType') {
      path = `/${this.config.appName}/devices/types?add-device-type=true`;
    } else if (item.key === 'hourlyConsumption') {
      path = `/${this.config.appName}/consumption?add-hourly-consumption=true`;
    } else if (item.key === 'role') {
      path = `/${this.config.appName}/roles?add-new-role=true`;
    } else if (item.key === 'group') {
      path = `/${this.config.appName}/groups?add-new-group=true`;
    } else if (item.key === 'policy') {
      path = `/${this.config.appName}/policy/add`;
    } else if (item.key === 'geo-fence') {
      path = `/${this.config.appName}/geo-fences?add-new-geo-fence=true`;
    } else if (item.key === 'certificate') {
      path = `/${this.config.appName}/configurations/certificates?add-new-certificate=true`;
    } else if (item.key === 'dep-profile') {
      path = `/${this.config.appName}/devices/dep-profiles?add-dep-profile=true`;
    } else if (item.key === 'notifications') {
      path = `/${this.config.appName}/notifications`;
    } else if (item.key === 'billing') {
      path = `/${this.config.appName}/billing`;
    } else if (item.key === 'change-password') {
      this.onChangePassword();
    } else if (item.key === 'ui-tour') {
      this.setState({ run: true, stepIndex: 0 });
    } else if (item.key === 'logout') {
      archiveHubspotConversations(this.config);
      this.logout();
    }

    this.props.history.push(path);
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      [ACTIONS.CLOSE].includes(action)
    ) {
      this.setState({ run: false });
      this.setState({ stepIndex: 0 });
    }
  };

  render() {
    const { collapsed, run, stepIndex } = this.state;
    const { t, theme } = this.props;
    const logo = randomizeImageUrlToAvoidLoadingFromCache(theme.logo.logoUrl);
    const logoIcon = randomizeImageUrlToAvoidLoadingFromCache(
      theme.logoIcon.logoIconUrl,
    );

    const steps = [
      {
        target: '#create-btn',
        title: t('label_manageConfigurationTitle'),
        content: t('label_createBtnTourGuideText'),
        continuous: true,
        disableBeacon: true,
      },
      {
        target: '#profile',
        title: t('label_manageUserProfileTitle'),
        content: t('label_userProfileTourGuideText'),
        disableBeacon: true,
      },
      {
        target: '#enrollDevices',
        title: t('label_enrollDeviceTitle'),
        content: (
          <div>
            <p>
              {t('label_enrollmentTourGuideText')}{' '}
              {t('label_referToTourGuideText')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/enrollment/`}
              >
                {t('label_enrollmentGuide')}
              </a>{' '}
              {t('label_moreInformationTourGuideText')}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '#allDevicesPenAssembly',
        title: t('label_viewDevicesTitle'),
        content: (
          <div>
            <p>
              {t('label_allDevicesTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/device/`}
              >
                {t('label_enrolmentGuideDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '#policies',
        title: t('label_policiesTitle'),
        content: (
          <div>
            <p>
              {t('label_policiesTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/policies/`}
              >
                {t('label_enrolmentGuidePolicyDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '#configurations',
        title: t('label_manageConfigurationTitle'),
        content: (
          <div>
            <p>
              {t('label_configurationsTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/configurations/`}
              >
                {t('label_enrolmentGuideConfigurationDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '#app-store',
        title: t('label_storeTitle'),
        content: (
          <div>
            <p>
              {t('label_appStoreTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/store/`}
              >
                {t('label_enrolmentGuideAppStoreDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '#app-publisher',
        title: t('label_publisherTitle'),
        content: (
          <div>
            <p>
              {t('label_appPublisherTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/publisher/`}
              >
                {t('label_enrolmentGuideAppPublisherDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true,
      },
    ];

    const items = [
      this.getItem(
        <span id="flow_layout">{t('home_menu_factory')}</span>,
        'flow_layout',
        <LayoutOutlined />,
        [
          this.getItem(
            t('home_menu_flow_layout'),
            'allFactoryFlowLayoutsPenAssembly',
            <LayoutOutlined />,
            null,
            styles.customItem,
          ),
          this.getItem(
            t('home_menu_weekly_summary'),
            'allFactoryFlowlayoutsWeeklySummary',
            <FieldTimeOutlined />,
            null,
            styles.customItem,
          ),
        ]
      ),
      this.getItem(
        <span id="output">{t('home_menu_output')}</span>,
        'output',
        <CarryOutOutlined />,
        [
          this.getItem(
            t('home_menu_penAssemly'),
            'allOutputPenAssembly',
            <EditOutlined />,
            null,
            styles.customItem,
          ),
        ]
      ),
      this.getItem(
        t('home_menu_breakdowns'),
        'allBreakdowns',
        <SettingOutlined />,
        null,
        styles.customItem,
      ),
      this.getItem(
        t('home_menu_energy'),
        'allEnergy',
        <SettingOutlined />,
        null,
        styles.customItem,
      ),
      this.getItem(
        t('home_menu_quality'),
        'allQuality',
        <ProjectOutlined />,
        null,
        styles.customItem,
      ),
      this.getItem(
        <span id="performance">{t('home_menu_performance')}</span>,
        'performance',
        <RiseOutlined />,
        [
          this.getItem(
            <span id="machines">{t('home_menu_machines')}</span>,
            'machines',
            <SettingOutlined />,
            [
              isAuthorized(
                this.config.scopes,
                ['perm:devices:view'],
                null,
                null,
              ) &&
              this.getItem(
                t('home_menu_penAssemly'),
                'allDevicesPenAssembly',
                <EditOutlined />,
                null,
                styles.customItem,
              ),
            ]
          ),
          this.getItem(
            <span id="operators">{t('home_menu_operators')}</span>,
            'operators',
            <TeamOutlined />,
            [
              this.getItem(
                t('home_menu_penAssemly'),
                'allOperatorsPenAssembly',
                <EditOutlined />,
                null,
                styles.customItem,
              ),
            ]
          ),
          this.getItem(
            <span id="products">{t('home_menu_products')}</span>,
            'products',
            <BookOutlined />,
            [
              this.getItem(
                t('label_product_pens'),
                'allProductsPenAssembly',
                <EditOutlined />,
                null,
                styles.customItem,
              ),
            ]
          ),
        ]
      ),
      this.getItem(
        <span id="configurations">{t('home_menu_configurations')}</span>,
        'configurations',
        <SettingOutlined />,
        [
          this.getItem(t('home_menu_machines'), 'devices-config', <GoldOutlined />, [
            isAuthorized(this.config.scopes, ['perm:devices:view']) &&
            this.getItem(
              <span id="deviceTypes">{t('label_machineTypes')}</span>,
              'deviceTypes',
              null,
              null,
              styles.customItem,
            ),
            isAuthorized(this.config.scopes, ['perm:device:enroll']) &&
            this.getItem(
              <span id="enrollDevices">{t('home_menu_enrollMachine')}</span>,
              'enroll',
              null,
              null,
              styles.customItem,
            ),
          ]),
          this.getItem(
            <span id="consumption">{t('home_menu_hourly_consumption')}</span>,
            'consumption',
            <BookOutlined />,
            [
              this.getItem(
                t('home_menu_hourly_consumption'),
                'allHourlyConsumption',
                <EditOutlined />,
                null,
                styles.customItem,
              ),
            ]
          ),
          isAuthorized(this.config.scopes, ['perm:users:user-details']) &&
            this.getItem(
              t('home_menu_users'),
              'users',
              <UserOutlined />,
              null,
              styles.customItem,
            ),
          isAuthorized(this.config.scopes, ['perm:roles:view']) &&
            this.getItem(
              t('home_menu_roles'),
              'roles',
              <BookOutlined />,
              null,
              styles.customItem,
            ),
          isAuthorized(this.config.scopes, [
            ['perm:admin-groups:view', 'perm:groups:groups'],
          ]) &&
            this.getItem(
              t('home_menu_groups'),
              'groups',
              <DeploymentUnitOutlined />,
              null,
              styles.customItem,
            ),
          isAuthorized(this.config.scopes, ['perm:admin:certificates:view']) &&
            this.getItem(
              t('home_menu_certificates'),
              'certificates',
              <SafetyCertificateOutlined />,
              null,
              styles.customItem,
            ),
          isAuthorized(this.config.scopes, [
            [
              'perm:view-configuration',
              'perm:android:view-configuration',
              'perm:ios:view-configuration',
              'perm:windows:view-configuration',
            ],
          ]) &&
            this.getItem(
              t('home_menu_platform'),
              'platform-configuration',
              <ControlOutlined />,
              null,
              styles.customItem,
            ),
        ],
      ),
      // this.getItem(
      //   <span id="app-store">{t('home_menu_appStore')}</span>,
      //   'store',
      //   <ApiOutlined />,
      //   null,
      //   styles.customItem,
      // ),
      // this.getItem(
      //   <span id="app-publisher">{t('home_menu_appPublisher')}</span>,
      //   'app-publisher',
      //   <ApiOutlined />,
      //   null,
      //   styles.customItem,
      // ),
      // this.config.analyticEnabledDomains.includes(this.config.userDomain) &&
      //   this.getItem(
      //     t('home_menu_analytics'),
      //     'analytics',
      //     <ReconciliationOutlined />,
      //     null,
      //     styles.customItem,
      //   ),
      // this.getItem(
      //   t('home_menu_deviceTracker'),
      //   'device-tracker',
      //   <ApiOutlined />,
      //   null,
      //   styles.customItem,
      // ),
    ];

    const headerItems = [
      this.getItem(<LanguageChooser />, 'language', null, null, 'profile'),
      this.config.isEnableMDMReportView &&
        this.getItem(
          <Tooltip title={t('home_menu_MDMReport')} placement="bottom">
            <Button
              href={'/mdm-reports'}
              target={'_blank'}
              shape="circle"
              icon={
                <Avatar
                  style={{
                    backgroundColor: '#388e9f',
                    verticalAlign: 'middle',
                  }}
                  size="small"
                  gap={4}
                >
                  {'R'}
                </Avatar>
              }
            />
          </Tooltip>,
          'report',
          null,
          null,
          null,
        ),
      isAuthorized(this.config.scopes, ['perm:notifications:view']) &&
        this.getItem(
          <Tooltip title={t('home_menu_deviceNotification')} placement="bottom">
            <BellOutlined id="notify" className={styles.notificationButton} />
          </Tooltip>,
          'notifications',
          null,
          null,
          null,
        ),
      this.getItem(
        <Avatar
          id="profile"
          className={styles.avatar}
          icon={<UserOutlined />}
        />,
        'user-avatar',
        null,
        [
          this.getItem(this.config.user, this.config.user),
          this.getItem(t('home_menu_changePassword'), 'change-password'),
          !this.config.excludedBillingTenants.includes(
            this.state.tenantDomain,
          ) && this.getItem(t('label_Usage'), 'billing'),
          this.getItem(t('home_menu_uiTour'), 'ui-tour'),
          this.getItem(t('home_menu_logout'), 'logout'),
        ],
        null,
      ),
    ];

    return (
      <>
        <Joyride
          steps={steps}
          showSkipButton={true}
          continuous={true}
          tooltipComponent={TooltipComponent}
          autoStart
          callback={this.handleJoyrideCallback}
          run={run}
          stepIndex={stepIndex}
        />
        <Layout>
          <Sider
            className="site-layout-background"
            breakpoint="lg"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            width={'258px'}
            style={{ minHeight: '100vh' }}
          >
            <div>
              <Link to={`/${this.config.appName}`}>
                <div className={styles.logo}>
                  {collapsed ? (
                    <img src={logoIcon} alt="Logo" />
                  ) : (
                    <img src={logo} alt="Logo" />
                  )}
                </div>
              </Link>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              onClick={this.sliderClick}
              selectedKeys={[this.state.currentRoute]}
              defaultOpenKeys={['iot-device-mgt', 'devices']}
              style={{
                background: 'transparent',
              }}
              items={items}
            />
          </Sider>
          <Layout className="site-layout">
            <Header className={styles.header}>
              <AimOutlined className={styles.searchIcon} />
              <Navigator />
              <Menu
                mode="horizontal"
                className={styles.rightAlignedMenu}
                onClick={this.headerClick}
                selectedKeys={[this.state.currentRoute]}
                items={headerItems}
              />
            </Header>

            <RoutesContext.Provider
              value={{ setCurrentRoute: this.setCurrentRoute }}
            >
              <Content style={{ margin: '20px 20px' }}>
                <Layout
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    minHeight: '80vh',
                  }}
                >
                  <Switch>
                    {this.state.routes.map(route => (
                      <RouteWithSubRoutes key={route.path} {...route} />
                    ))}
                  </Switch>
                </Layout>
              </Content>
            </RoutesContext.Provider>
            <Footer
              style={{
                textAlign: 'center',
                position: 'sticky',
                width: '100%',
                bottom: 0,
                height: 20,
                paddingBottom: 40,
              }}
            >
              {ReactHtmlParser(theme.footerText)}
            </Footer>
          </Layout>
          <ChangePassword
            isChangePasswordModalVisible={
              this.state.isChangePasswordModalVisible
            }
            onCancelChangedPassword={this.onCancelChangedPassword}
            logout={this.logout}
          />
        </Layout>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(Home));
