/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Row, Col } from 'antd';
import SearchQueryModal from './components/SearchQueryModal';
import styles from './styles.module.css';
import { SearchOutlined } from '@ant-design/icons';

class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPayload: {
        conditions: [],
      },
    };
  }

  payload = [];

  fetchPayload = payload => {
    this.payload = payload;
  };

  onClickSearch = () => {
    const payload = {
      conditions: this.payload,
    };
    this.props.callback(payload);
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={22}>
            <SearchQueryModal
              callback={payload => this.fetchPayload(payload)}
            />
          </Col>
          <Col span={2}>
            <div>
              <Button
                type={'primary'}
                onClick={this.onClickSearch}
                size={'large'}
                className={styles.searchButton}
                icon={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdvancedSearch;
