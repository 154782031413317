/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { PageHeader, Result, Typography } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';
import PolicyPriority from './components/PolicyPriority';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import Authorized from '../../../../../../components/Authorized';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { Trans, withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title, Paragraph } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/policies`,
      breadcrumbName: t('home_menu_policies'),
    },
    {
      breadcrumbName: t('label_priorities'),
    },
  ];
};

class PolicyPriorityView extends React.Component {
  render() {
    const config = this.props.context;
    const { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/policies/view'}
        />
        <Authorized
          scope={['perm:policies:get-details']}
          yes={
            <div>
              <div>
                <PageHeader
                  className={styles.pageHeader}
                  title={
                    <Title level={1}>
                      <span className={styles.icon}>
                        <ContainerOutlined />
                      </span>
                      {t('label_policyListPriorityOrder')}
                    </Title>
                  }
                  ghost={false}
                  breadcrumb={
                    <BreadcrumbComponent
                      breadcrumbList={routes(config.appName, t)}
                    />
                  }
                >
                  <Paragraph className={styles.description}>
                    <Trans i18nKey="policyPriorityDescription">
                      The priority by which the policies should be enforced on
                      the devices, is as shown here. You can change this
                      priority order either by clicking Edit Priority, or simply
                      by dragging a policy and moving it around. Click Save New
                      Priority Order after you have reordered the policy
                      priority, for your changes to take effect.
                    </Trans>
                  </Paragraph>
                </PageHeader>
                <div className={styles.container}>
                  <PolicyPriority />
                </div>
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PolicyPriorityView));
