/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../components/ConfigContext';
import { Collapse, PageHeader, Radio, Result, Typography } from 'antd';
import styles from './styles.module.css';
import {
  FundViewOutlined,
  NodeIndexOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { RoutesContext } from '../../index';
import GeoFencesTable from './component/GeoFencesTable';
import Filter from '../../components/Filter';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title } = Typography;
const { Panel } = Collapse;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_geoFences'),
    },
  ];
};

const searchFields = t => {
  return [
    {
      name: 'name',
      placeholder: t('label_geoFenceName'),
      label: t('label_name'),
      type: 'input',
    },
  ];
};

class GeoFences extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: {},
      filters: {},
      loading: false,
      viewType: 'listView',
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('geo-fences');
  }

  handleModeChange = e => {
    const viewType = e.target.value;
    this.setState({ viewType });
  };

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  render() {
    const { viewType } = this.state;
    const { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/devices/owning-device/manage-geo-fence'
          }
        />
        <Authorized
          scope={['perm:geo-service:geo-fence']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <NodeIndexOutlined />
                    </span>
                    {t('home_menu_geoFences')}
                  </Title>
                }
                ghost={false}
                extra={[
                  <div key={'view'}>
                    <Radio.Group
                      onChange={this.handleModeChange}
                      defaultValue={'listView'}
                      value={viewType}
                    >
                      <Radio.Button
                        value={'listView'}
                        style={{ borderRadius: '20px 0 0 20px' }}
                      >
                        <UnorderedListOutlined /> {t('label_listView')}
                      </Radio.Button>
                      <Radio.Button
                        value={'mapView'}
                        style={{ borderRadius: '0 20px 20px 0' }}
                      >
                        <FundViewOutlined /> {t('label_mapView')}
                      </Radio.Button>
                    </Radio.Group>
                  </div>,
                ]}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(this.config.appName, t)}
                  />
                }
              />
              <div className={styles.table}>
                <Collapse
                  bordered={false}
                  expandIcon={() => <SearchOutlined />}
                  className={styles.searchCollapse}
                >
                  <Panel
                    header={t('form_searchGeoFence')}
                    key="1"
                    style={{ borderColor: '#fff' }}
                  >
                    <Filter
                      filters={searchFields(t)}
                      callback={(params, values) =>
                        this.fetchFilterData({}, values)
                      }
                    />
                  </Panel>
                </Collapse>
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/admin/groups/view OR\n' +
                    '/permission/admin/device-mgt/groups/view'
                  }
                />
                <GeoFencesTable
                  viewType={viewType}
                  filters={this.state.filters}
                />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}
GeoFences.contextType = RoutesContext;
export default withConfigContext(withTranslation()(GeoFences));
