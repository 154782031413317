/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  PageHeader,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import { QuestionCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

class MDMProfile extends React.Component {
  formRef = React.createRef();

  mdmCert = '';
  mdmCertName = '';

  constructor(props) {
    super(props);
    this.state = {
      MDMFileList: [],
    };
  }

  componentDidMount() {
    let policyUIPayload = [...this.props.platformConfigData];
    let initialValues = {};

    for (let policyConfig of policyUIPayload) {
      switch (policyConfig.name) {
        case 'configCountry':
          initialValues.configCountry = policyConfig.value;
          break;
        case 'configState':
          initialValues.configState = policyConfig.value;
          break;
        case 'configLocality':
          initialValues.configLocality = policyConfig.value;
          break;
        case 'configOrganization':
          initialValues.configOrganization = policyConfig.value;
          break;
        case 'configOrganizationUnit':
          initialValues.configOrganizationUnit = policyConfig.value;
          break;
        case 'organizationDisplayName':
          initialValues.organizationDisplayName = policyConfig.value;
          break;
        case 'MDMCertTopicID':
          initialValues.MDMCertTopicID = policyConfig.value;
          break;
        case 'MDMCertPassword':
          initialValues.MDMCertPassword = policyConfig.value;
          break;
        case 'MDMCert':
          this.mdmCert = policyConfig.value;
          break;
        case 'MDMCertName':
          this.mdmCertName = policyConfig.value;
          initialValues.MDMFileList = {
            fileList: [
              {
                name: policyConfig.value,
              },
            ],
          };
          this.setState({
            MDMFileList: [
              {
                uid: '1',
                name: policyConfig.value,
              },
            ],
          });
          break;
        case 'iOSConfigMDMMode':
          initialValues.iOSConfigMDMMode = policyConfig.value;
          break;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    values.MDMCertName = this.mdmCertName;
    values.MDMCert = this.mdmCert;
    delete values.MDMFileList;
    this.props.callback(values);
  };

  onFinishFailed = err => {
    console.log('Finish Failed ....... ', err);
  };

  beforeUpload = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.mdmCert = e.target.result;
    };
    // Prevent upload
    return false;
  };

  handleMDMFileChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ MDMFileList: fileList });
    if (fileList.length === 0) {
      this.mdmCertName = '';
      this.mdmCert = '';
    } else {
      this.mdmCertName = fileList[0].name;
    }
  };

  render() {
    const { MDMFileList } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Form
            {...formItemLayout}
            name="MDMProfileForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={t('label_SCEPCertificate')}
                />
              </Divider>
            </div>
            <Form.Item
              label={
                <span>
                  {t('label_country')} (C)&nbsp;
                  <Tooltip
                    title={`${t('label_countryCode')} (${t('ex_Txt', {
                      exampleTxt: 'LK, US',
                    })})`}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="configCountry"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPCountry'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {t('label_state')} (ST)&nbsp;
                  <Tooltip title={t('label_state')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="configState"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPState'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_locality')} (L)&nbsp;
                  <Tooltip title={t('label_locality')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="configLocality"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPLocality'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_organization')} (O)&nbsp;
                  <Tooltip title={t('label_organizationName')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="configOrganization"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPOrganization'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_organizationUnit')} (OU)&nbsp;
                  <Tooltip title={t('label_organizationUnit')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="configOrganizationUnit"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPOrganizationUnit'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={t('label_profile')}
                />
              </Divider>
            </div>
            <Form.Item
              label={
                <span>
                  {t('label_organizationDisplayName')}&nbsp;
                  <Tooltip title={t('label_organizationDisplayName')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="organizationDisplayName"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_organizationDisplayName'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={t('label_MDMCertificate')}
                />
              </Divider>
            </div>
            <Form.Item
              label={
                <span>
                  {t('label_MDMCertificate')}e&nbsp;
                  <Tooltip title={t('form_uploadMDMCertificate')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_MDMCertificate'),
                  }),
                },
                {
                  validator(rule, value) {
                    if (
                      !value ||
                      (value && value.fileList && value.fileList.length === 0)
                    ) {
                      return Promise.reject(
                        t('form_generalRequiredMsg', {
                          label: t('label_MDMCertificate'),
                        }),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name="MDMFileList"
            >
              <Upload.Dragger
                beforeUpload={this.beforeUpload}
                onChange={this.handleMDMFileChange}
                fileList={MDMFileList}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('uploadMDMCertificate_infoTxt')}
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_MDMCertificatePassword')}&nbsp;
                  <Tooltip title={t('label_MDMCertificatePassword')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="MDMCertPassword"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_MDMCertificatePassword'),
                  }),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_MDMCertificateTopicID')}&nbsp;
                  <Tooltip title={t('label_MDMCertificateTopicID')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="MDMCertTopicID"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_MDMCertificateTopicID'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="iOSConfigMDMMode"
              label={
                <span>
                  {t('label_mode')}&nbsp;
                  <Tooltip title={t('label_releaseMode')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              initialValue="1"
            >
              <Select style={{ width: 120 }}>
                <Option value="1">{t('label_production')}</Option>
                <Option value="2">{t('label_developer')}</Option>
              </Select>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MDMProfile);
