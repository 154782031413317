/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Divider, Form, Input, PageHeader, Select } from 'antd';
import { withTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 20 },
    xl: { span: 20 },
  },
};

class GeoAlerts extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedPolicy: null,
    };
  }

  initialValueSet = {};
  prevValueSet = {};

  componentDidMount() {
    const { platformConfigData } = this.props;
    platformConfigData.forEach(data => {
      this.initialValueSet[data.name] = data.value;
    });
    let params = new URL(window.location).searchParams;
    let actionType = params.get('actionType');
    if (actionType) {
      this.onPolicySelect(actionType.toUpperCase());
    }
  }

  onFinishFailed = err => {
    console.log('Finish Failed ....... ', err);
  };

  onFinish = values => {
    values.actionTypes = ['MAIL'];
    const configPayload = {};
    if (this.state.selectedPolicy) {
      configPayload[this.state.selectedPolicy] = JSON.stringify(values);
      this.props.callback(configPayload);
    }
  };

  onPolicySelect = value => {
    this.setState({ selectedPolicy: value });
    if (this.initialValueSet[value]) {
      this.formRef.current.setFieldsValue(
        JSON.parse(this.initialValueSet[value]),
      );
    } else {
      this.formRef.current.resetFields();
    }
  };

  render() {
    const { selectedPolicy } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_EmailConfigs')}
            />
          </Divider>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <Select
            showSearch
            style={{ width: 200 }}
            value={selectedPolicy}
            placeholder={t('label_selectPolicy')}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={this.onPolicySelect}
          >
            <Option value="GEO_FENCE_IN">{t('title_geoFenceIn')}</Option>
            <Option value="GEO_FENCE_OUT">{t('title_geoFenceOut')}</Option>
          </Select>
        </div>
        <div>
          <Form
            {...formItemLayout}
            name="GeneralConfigurationForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Form.Item
              label={t('label_mailReceivers')}
              name="mailReceivers"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_mailReceivers'),
                  }),
                },
                {
                  validator(rule, value) {
                    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
                    let isValid = true;
                    if (value) {
                      value.forEach(email => {
                        if (!pattern.test(email)) {
                          isValid = false;
                        }
                      });
                    }
                    if (!isValid) {
                      return Promise.reject(t('invalidEmail_txt'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select
                disabled={!selectedPolicy}
                mode="tags"
                style={{ width: '100%' }}
                tokenSeparators={[',']}
              />
            </Form.Item>
            <Form.Item
              label={t('label_mailSubject')}
              name="mailSubject"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_mailSubject'),
                  }),
                },
              ]}
            >
              <Input
                disabled={!selectedPolicy}
                placeholder={t('form_addMailSubject')}
              />
            </Form.Item>
            <Form.Item
              label={t('label_mailBody')}
              name="mailBody"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_mailBody'),
                  }),
                },
              ]}
            >
              <TextArea disabled={!selectedPolicy} rows={4} />
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GeoAlerts);
