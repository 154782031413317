/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  DownOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  PageHeader,
  Typography,
  Button,
  Result,
  Dropdown,
  Menu,
  Alert,
  Row,
} from 'antd';
import DeviceLocationMap from './components/DeviceLocationMap';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../components/ConfigContext';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import WiFiConnectivityMap from '../../../../../public/images/Wifi_signal_strength.png';
import MobileConnectivityMap from '../../../../../public/images/Mobile_signal_strength.png';
import NoAccessDescriptionModal from '../../components/NoAccessDescriptionModal';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title, Paragraph } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_deviceLocations'),
    },
  ];
};

class DeviceLocations extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    this.state = {
      data: {},
      loading: false,
      viewType: 'geoFenceInvisible',
      advancedItem: { isViewNoAccessModal: false },
    };
    const items = [
      this.getItem(t('label_WiFiConnectivityMap'), 'wifi'),
      this.getItem(t('label_MobileConnectivityMap'), 'mobile'),
    ];
    this.menu = (
      <Menu onClick={e => this.handleAdvancedMenuClick(e)} items={items} />
    );
  }

  componentDidMount() {
    this.context.setCurrentRoute('device-locations');
  }

  onPreview = () => {
    const viewType = 'geoFenceVisible';
    this.setState({ viewType });
  };

  onCancelPreview = () => {
    const viewType = 'geoFenceInvisible';
    this.setState({ viewType });
  };

  handleAdvancedMenuClick = event => {
    const { t } = this.props;
    const menuKey = event.key;
    let title;
    let content;
    let imgSrc;
    let redirectPage;

    switch (menuKey) {
      case 'wifi':
        redirectPage =
          window.location.origin +
          `/${this.config.appName}/analytics/device-connectivity/wifi-connectivity?operator=All&panelId=4`;
        title = t('label_WiFiConnectivityMap');
        imgSrc = WiFiConnectivityMap;
        content = (
          <div>
            <Alert
              message={t('analytic_warningTxt1', {
                label: t('label_WiFiConnectivityMap'),
              })}
              type="warning"
            />
          </div>
        );
        break;
      case 'mobile':
        redirectPage =
          window.location.origin +
          `/${this.config.appName}/analytics/device-connectivity/mobile-connectivity?operator=All&panelId=3`;
        title = t('label_MobileConnectivityMap');
        imgSrc = MobileConnectivityMap;
        content = (
          <div>
            <Alert
              message={t('analytic_warningTxt1', {
                label: t('label_MobileConnectivityMap'),
              })}
              type="warning"
            />
          </div>
        );
        break;
    }

    if (this.config.analyticEnabledDomains.includes(this.config.userDomain)) {
      window.open(redirectPage, '_blank');
    } else {
      this.setState({
        advancedItem: {
          title: title,
          imgSrc: imgSrc,
          content: content,
          isViewNoAccessModal: true,
        },
      });
    }
  };

  onCloseAdvancedNoAccessModal = () => {
    this.setState({
      advancedItem: {
        isViewNoAccessModal: false,
      },
    });
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  render() {
    const config = this.props.context;
    const { viewType, advancedItem } = this.state;
    const { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/devices/owning-device/view-analytics'
          }
        />
        <Authorized
          scope={['perm:geo-service:analytics-view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <EnvironmentOutlined />
                    </span>
                    {t('home_menu_deviceLocations')}
                  </Title>
                }
                ghost={false}
                extra={[
                  <Row key={'extra'}>
                    <div key={'connectivity'}>
                      <Dropdown overlay={this.menu}>
                        <Button
                          type="primary"
                          style={{
                            marginRight: 25,
                          }}
                        >
                          Connectivity <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                    <div key={'view'}>
                      <Button
                        onClick={this.onPreview}
                        style={{
                          marginRight: 50,
                          display:
                            viewType === 'geoFenceInvisible'
                              ? 'inline'
                              : 'none',
                        }}
                      >
                        <EyeOutlined /> {t('label_viewGeoFences')}
                      </Button>
                      <Button
                        onClick={this.onCancelPreview}
                        style={{
                          marginRight: 50,
                          display:
                            viewType === 'geoFenceVisible' ? 'inline' : 'none',
                        }}
                      >
                        <EyeInvisibleOutlined /> {t('label_removeGeoFences')}
                      </Button>
                    </div>
                  </Row>,
                ]}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              >
                <Paragraph className={styles.description}>
                  {t('deviceLocation_descriptionTxt')}
                </Paragraph>
              </PageHeader>
              <div className={styles.container}>
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/devices/owning-device/manage-geo-fence'
                  }
                />
                <DeviceLocationMap viewType={viewType} />
                {!this.config.analyticEnabledDomains.includes(
                  this.config.userDomain,
                ) &&
                  advancedItem.isViewNoAccessModal && (
                    <div>
                      <NoAccessDescriptionModal
                        title={advancedItem.title}
                        content={advancedItem.content}
                        imgSrc={advancedItem.imgSrc}
                        isModalView={advancedItem.isViewNoAccessModal}
                        onCloseAdvancedNoAccessModal={
                          this.onCloseAdvancedNoAccessModal
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

DeviceLocations.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DeviceLocations));
