/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Alert,
  Button,
  Divider,
  Form,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import CustomAlerts from '../CustomAlerts';
import CustomUpload from '../CustomUpload';
import CustomCheckbox from '../CustomCheckbox';
import CustomSearchInput from '../CustomSearchInput';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

const { Title, Text } = Typography;

class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    const { itemData } = this.props;
    const { value } = itemData;
    if (value.length > 0) {
      const dataSourceArray = value.map((obj, index) => ({
        key: index,
        ...obj,
      }));
      this.setState({ dataSource: dataSourceArray });
    } else {
      this.setState({
        dataSource: [],
      });
    }
  }

  getColumnTitle = columnData => {
    const { t } = this.props;
    return (
      <span>
        {t(`${columnData.name}`)}&nbsp;
        {columnData.tooltip && (
          <Tooltip
            title={
              <div>
                <div>{t(`${columnData.tooltip}`)}</div>
                <div className={styles.docLink}>
                  {columnData.docLink && (
                    <a onClick={() => this.onClickDocLink(columnData.docLink)}>
                      {' '}
                      {t('label_SeeMoreDetails')}
                    </a>
                  )}
                </div>
              </div>
            }
            placement="right"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </span>
    );
  };

  onClickDocLink = docLink => {
    window.open(docLink, '_blank');
  };

  getColumns = columnArr => {
    const columnArray = [];
    const deleteAction = {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <Form.Item>
            <Button
              className={styles.deleteRowButton}
              key={index}
              shape="circle"
              type="danger"
              icon={<LegacyIcon type={'delete'} />}
              onClick={e => this.handleDeleteRow(e, record.key)}
            />
          </Form.Item>
        );
      },
    };
    const columnArrClone = [...columnArr];
    Object.values(columnArrClone).map((columnData, c) => {
      const columnTitle = this.getColumnTitle(columnData);
      if (columnData.hasOwnProperty('input')) {
        const column = {
          title: columnTitle,
          dataIndex: `${columnData.key}`,
          key: `${columnData.key}`,
          render: (name, row) => {
            return (
              <CustomInput
                itemData={columnData}
                defaultValue={name}
                disabled={this.props.disabled}
                callback={value => {
                  this.setPayload(columnData.key, value, row.key);
                }}
              />
            );
          },
        };
        columnArray.push(column);
      } else if (columnData.hasOwnProperty('upload')) {
        const column = {
          title: columnTitle,
          dataIndex: `${columnData.key}`,
          key: `${columnData.key}`,
          render: (name, row) => {
            return (
              <CustomUpload
                itemData={columnData}
                defaultValue={name}
                disabled={this.props.disabled}
                callback={value => {
                  this.setPayload(columnData.key, value, row.key);
                }}
              />
            );
          },
        };
        columnArray.push(column);
      } else if (columnData.hasOwnProperty('select')) {
        const column = {
          title: columnTitle,
          dataIndex: `${columnData.key}`,
          key: `${columnData.key}`,
          render: (name, row) => {
            return (
              <CustomSelect
                itemData={columnData}
                defaultValue={name}
                disabled={this.props.disabled}
                callback={value => {
                  this.setPayload(columnData.key, value, row.key);
                }}
              />
            );
          },
        };
        columnArray.push(column);
      } else if (columnData.hasOwnProperty('checkbox')) {
        columnData.label = columnData.name;
        const column = {
          title: columnTitle,
          dataIndex: `${columnData.key}`,
          key: `${columnData.key}`,
          render: (name, row) => {
            return (
              <CustomCheckbox
                itemData={columnData}
                defaultValue={name}
                disabled={this.props.disabled}
                callback={value => {
                  this.setPayload(columnData.key, value, row.key);
                }}
              />
            );
          },
        };
        columnArray.push(column);
      } else if (columnData.hasOwnProperty('searchInput')) {
        const column = {
          title: columnTitle,
          dataIndex: `${columnData.key}`,
          key: `${columnData.key}`,
          render: (name, row) => {
            return (
              <CustomSearchInput
                itemData={columnData}
                defaultValue={name}
                disabled={this.props.disabled}
                callback={value => {
                  this.setPayload(columnData.key, value, row.key);
                }}
              />
            );
          },
        };
        columnArray.push(column);
      }
    });
    if (!this.props.disabled) {
      columnArray.push(deleteAction);
    }
    return [...columnArray];
  };

  addRow = () => {
    const dataSource = [...this.state.dataSource];
    let itemKeyArray = [];
    dataSource.forEach(data => {
      itemKeyArray.push(data.key);
    });
    if (itemKeyArray.length > 0) {
      dataSource.push({ key: Math.max(...itemKeyArray) + 1 });
    } else {
      dataSource.push({ key: 0 });
    }
    this.setState({ dataSource });
  };

  handleDeleteRow = (e, rowKey) => {
    const dataSource = [...this.state.dataSource];
    const { itemData, t } = this.props;
    let index = dataSource.findIndex(x => x.key === rowKey);
    dataSource.splice(index, 1);
    this.setState({ dataSource });

    const item = { ...this.props.itemData };
    item.value = dataSource;

    if (itemData.isNoneEmptyTable && item.value.length === 0) {
      if (!item.error) {
        item.error = {};
      }
      item.error.required = t('form_nonEmptyTableMsg');
    }
    this.props.callback(item);
  };

  setPayload = (key, value, rowKey) => {
    const { dataSource } = this.state;
    const { itemData, t } = this.props;
    let i = dataSource.findIndex(x => x.key === rowKey);
    dataSource[i][key] = value.value;

    const item = { ...this.props.itemData };
    item.value = dataSource;
    if (item.error) {
      delete item.error;
    }
    if (
      itemData.isNoneEmptyTable &&
      (item.value.length === 0 || item.value.length === 1)
    ) {
      let isEmpty = true;
      if (item.value.length === 1) {
        isEmpty = false;
        let isEmptyCheckArray = [];
        let itemValueClone = { ...item.value[0] };
        delete itemValueClone.key;
        Object.values(itemValueClone).forEach(itemValue => {
          if (itemValue !== '') {
            isEmptyCheckArray.push(false);
          } else {
            isEmptyCheckArray.push(true);
          }
        });
        isEmpty = !isEmptyCheckArray.includes(false);
      }
      if (isEmpty) {
        if (!item.error) {
          item.error = {};
        }
        item.error.required = t('form_nonEmptyTableMsg');
      }
    }
    let datasourceClone = [...dataSource];
    item.value = datasourceClone.map(({ key, ...rest }) => rest);
    this.props.callback(item);
  };

  render() {
    const { itemData, t } = this.props;
    const { dataSource } = this.state;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div className={styles.tableContainer}>
          <Row>
            <span>
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div className={styles.docLink}>
                        {itemData.docLink && (
                          <a
                            onClick={() =>
                              this.onClickDocLink(itemData.docLink)
                            }
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <div className={styles.addRowButton}>
            <Button
              type="primary"
              onClick={() => this.addRow()}
              disabled={this.props.disabled}
            >
              {t('label_add')}
            </Button>

            <Table
              dataSource={dataSource}
              columns={this.getColumns(itemData.table.columns, itemData)}
              rowKey="key"
              scroll={{ x: 'max-content' }}
            />
            <Alert
              style={{
                marginTop: 5,
                display:
                  itemData.error && itemData.error.required ? 'flex' : 'none',
              }}
              message={
                itemData.error &&
                itemData.error.required &&
                itemData.error.required
              }
              type="error"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomTable));
