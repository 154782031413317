/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext } from 'react';
import { PageHeader, Select, DatePicker, Row, Col } from 'antd';
import styles from './styles.module.css';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';
import { ProductDetailsContext } from '../../index';
import ConfigContext from '../../../../../../components/ConfigContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const routes = (productName, appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/products`,
      breadcrumbName: t('home_menu_products'),
    },
    {
      breadcrumbName: `${t('label_product')} - ${productName.charAt(0).toUpperCase() + productName.slice(1)}`,
    },
  ];
};

const { Option } = Select;

const Header = ({ shiftType, setShiftType, range, setRange, timeRanges, from, setFrom }) => {
  const productDetails = useContext(ProductDetailsContext);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  const handleFromDateChange = (date) => {
    const time =
      shiftType === "dayShift"
        ? date.startOf("day").set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        : shiftType === "nightShift"
        ? date.set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
        : null;
  
    setFrom(time ? time.valueOf() : null);
  };

  return (typeof productDetails.product !== 'undefined' ? (
    <PageHeader className={styles.pageHeader} ghost={false}>
      <div className={styles.positioning}>
        <Row justify="space-between" align="middle" gutter={8}>
        <Col>
            <BreadcrumbComponent
              breadcrumbList={routes(productDetails.product.PRODUCT, config.appName, t)}
            />
          </Col>
          <Col>
          <Row align="middle" gutter={[8, 8]}>
              <Col flex="auto">
                <Select
                  size="small"
                  className="shift-types-selector"
                  style={{ width: 175 }}
                  onChange={setShiftType}
                  value={shiftType}
                >
                  <Option key="dayShift" value="dayShift">
                    {t('label_day_shift')}
                  </Option>
                  <Option key="nightShift" value="nightShift">
                    {t('label_night_shift')}
                  </Option>
                  <Option key="all" value="all">
                    {t('label_all_shift')}
                  </Option>
                </Select>
              </Col>
              {shiftType !== 'all' ? (
                <Col>
                  <DatePicker
                    size="small"
                    className={`date-range-selector ${styles.commonStyle}`}
                    value={moment(from)}
                    onChange={handleFromDateChange}
                    disabledDate={(current) => current && current > moment().endOf('day')}
                  />
                </Col>
              ) : (
                <Col>
                  <Select
                    size="small"
                    className={`date-range-selector ${styles.commonStyle}`}
                    onChange={setRange}
                    value={range}
                  >
                    {timeRanges.map(({ id, label, value }) => (
                      <Option key={id} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </PageHeader>
  ) : (
    null
  ));

  // return (typeof productDetails.product !== 'undefined' ? ( 
  //   <PageHeader
  //       className={styles.pageHeader}
  //       ghost={false}
  //       breadcrumb={
  //         <BreadcrumbComponent
  //           breadcrumbList={routes(productDetails.product.PRODUCT, config.appName, t)}
  //         />
  //       }
  //       extra={
  //         <Row justify="space-between" align="middle">
  //           <Col>
  //             <Select
  //               size="small"
  //               className="shift-types-selector"
  //               style={{ width: 150, marginRight:10, marginBottom:10 }}
  //               onChange={setShiftType}
  //               value={shiftType}
  //             >
  //               <Option key="dayShift" value="dayShift">
  //                 {t('label_day_shift')}
  //               </Option>
  //               <Option key="nightShift" value="nightShift">
  //                 {t('label_night_shift')}
  //               </Option>
  //               <Option key="all" value="all">
  //                 {t('label_all_shift')}
  //               </Option>
  //             </Select>
  //           </Col>
  //           {shiftType !== 'all' ? (
  //             <Col>
  //               <DatePicker
  //                 size="small"
  //                 className={`${styles['date-range-selector']} ${styles.commonStyle}`}
  //                 value={moment(from)}
  //                 onChange={handleFromDateChange}
  //                 disabledDate={(current) => current && current > moment().endOf('day')}
  //               />
  //             </Col>
  //           ) : (
  //             <Col>
  //               <Select
  //                 size="small"
  //                 className={`${styles['date-range-selector']} ${styles.commonStyle}`}
  //                 onChange={setRange}
  //                 value={range}
  //               >
  //                 {timeRanges.map(({ id, label, value }) => (
  //                   <Option key={id} value={value}>
  //                     {label}
  //                   </Option>
  //                 ))}
  //               </Select>
  //             </Col>
  //           )}
  //         </Row>
  //       }
  //     >
  //     </PageHeader>
      
  //   ) : (
  //     null
  // ));
};
export default Header;
