/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { ReconciliationOutlined } from '@ant-design/icons';
import { PageHeader, Typography } from 'antd';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../components/ConfigContext';
import SubTypeList from './components/SubTypeList';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_analytics'),
    },
  ];
};

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    this.state = {
      filters: {},
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('analytics');
  }

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <ReconciliationOutlined />
                </span>
                {t('home_menu_analytics')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <div className={styles.table}>
            <SubTypeList />
          </div>
        </div>
      </div>
    );
  }
}

Analytics.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Analytics));
