/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { PageHeader, Result, Typography } from 'antd';
import CertificateTable from './components/CertificateTable';
import { RoutesContext } from '../../../../index';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import Authorized from '../../../../../../components/Authorized';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_configurations'),
    },
    {
      breadcrumbName: t('home_menu_certificates'),
    },
  ];
};

class Certificates extends React.Component {
  componentDidMount() {
    this.context.setCurrentRoute('certificates');
  }

  render() {
    const config = this.props.context;
    const { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/admin/certificates/view'}
        />
        <Authorized
          scope={['perm:admin:certificates:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <SettingOutlined />
                    </span>
                    {t('home_menu_certificates')}
                  </Title>
                }
                ghost={false}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              />
              <div className={styles.table}>
                <CertificateTable />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

Certificates.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Certificates));
