/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, notification } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import CertificateForm from './components/CertificateForm';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';

class AddCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-certificate');
    if (JSON.parse(name)) {
      this.setState({
        isModalVisible: true,
      });
    }
  };

  onCancelHandler = () => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      isModalVisible: false,
    });
  };

  fetchCertificateFromData = values => {
    this.addCertificate(values);
  };

  addCertificate = payload => {
    this.setState({ loading: true });
    const { t } = this.props;
    const data = [payload];

    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/certificate-mgt/v1.0/admin/certificates',
        data,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.setState({
            isModalVisible: false,
            loading: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addConfigMsg', {
              label: t('label_certificate'),
            }),
          });
          window.history.pushState(null, '', location.href.split('?')[0]);
          this.props.fetch();
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('label_certificate') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={t('label_addNewCertificate')}
        open={this.state.isModalVisible}
        onCancel={this.onCancelHandler}
        footer={null}
        maskClosable={false}
      >
        <CertificateForm
          action={'Add'}
          modalVisibility={this.state.isModalVisible}
          callback={values => this.fetchCertificateFromData(values)}
        />
      </Modal>
    );
  }
}

export default withConfigContext(withTranslation()(AddCertificate));
