/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Row,
  Tooltip,
  Typography,
  List,
  Button,
  Form,
  Divider,
} from 'antd';
import CustomAlerts from '../CustomAlerts';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

const { Title, Text } = Typography;

const buildRulesArray = (itemData, t) => {
  let rules = [];
  if (itemData.inputList.hasOwnProperty('rules')) {
    itemData.inputList.rules.forEach(rule => {
      rules.push({
        pattern: new RegExp(`${rule.logic}`),
        message: rule.hasOwnProperty('validationMessage')
          ? t(`${rule.validationMessage}`)
          : null,
      });
    });
  }
  return rules;
};

class CustomInputList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      listData: [''],
    };
  }

  componentDidMount() {
    const { itemData } = this.props;
    const { value } = itemData;
    if (value !== '' && value.length > 0) {
      this.setState({ listData: value });
    } else {
      this.setState({
        listData: [''],
      });
    }
  }

  setUpdatedItem = (index, value) => {
    const { listData } = this.state;
    listData[index] = value.target.value;
    this.setState({ listData });
    const item = { ...this.props.itemData };
    item.value = listData;

    this.formRef.current
      .validateFields()
      .then(values => {
        if (item.hasOwnProperty('error')) {
          delete item.error;
        }
      })
      .catch(errorInfo => {
        item.error = {};
        errorInfo.errorFields.forEach(err => {
          item.error[err.name[0]] = err.errors;
        });
      });

    this.props.callback(item);
  };

  handleDeleteRow = (e, index) => {
    const { listData } = this.state;
    listData.splice(index, 1);
    this.setState({ listData });
  };

  addRow = () => {
    const { listData } = this.state;
    listData.push('');
    this.setState({ listData });
  };

  render() {
    const { itemData, t } = this.props;
    const { listData } = this.state;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(`${itemData.divider}`)}</Text>
          </Divider>
        )}
        {itemData.subTitle && (
          <Title level={4}> {t(`${itemData.subTitle}`)} </Title>
        )}
        <CustomAlerts notifications={itemData.notifications} />
        <div className={styles.inputListContainer}>
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Button
            type="primary"
            onClick={() => this.addRow()}
            disabled={this.props.disabled}
            style={{ marginBottom: 5 }}
          >
            {t('label_add')}
          </Button>
          <Form ref={this.formRef}>
            <Row>
              <List header={t(itemData.label)} size="small" bordered>
                {listData.map((data, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <Button
                        key={index}
                        shape="circle"
                        disabled={this.props.disabled}
                        type="danger"
                        icon={<LegacyIcon type={'delete'} />}
                        onClick={e => this.handleDeleteRow(e, index)}
                        style={{ margin: 2 }}
                      />,
                    ]}
                  >
                    <Form.Item
                      name={itemData.key + index}
                      rules={buildRulesArray(itemData, t)}
                    >
                      <Input
                        key={Math.floor(Math.random() * 1000)}
                        placeholder={t(
                          `${itemData.inputList.placeholderValue}`,
                        )}
                        disabled={this.props.disabled}
                        defaultValue={data}
                        onBlur={value => this.setUpdatedItem(index, value)}
                        type={itemData.inputList.type}
                      />
                    </Form.Item>
                  </List.Item>
                ))}
              </List>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomInputList);
