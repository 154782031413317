/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import Header from './components/Header';
import ConfigContext from '../../../../components/ConfigContext';
import { Col, Result, Row, Spin } from 'antd';
import { RoutesContext } from '../../index';
import { useTranslation } from 'react-i18next';
import productsData from '../../../../../public/conf/products.json';
import IframeWrapper from '../../components/IframeWrapper';
import { grafanaUrlAccessible } from '../../../../services/utils/urlUtils';
import { timeRanges } from '../../../../services/utils/commonUtils';
import moment from 'moment';
import useSetRange from '../../../../components/UseSetRange';
import { iframeHandler } from '../../../../services/utils/iframeHandler';

export const ProductDetailsContext = React.createContext();
const ProductDetailsProvider = ProductDetailsContext.Provider;

const AtlasSingleProduct = props => {
  const { PRODUCT_ID } = props.match.params;
  const product = productsData.PRODUCTS.find(product => product.PRODUCT_ID === parseInt(PRODUCT_ID));
  const { config } = useContext(ConfigContext);
  const routesContext = useContext(RoutesContext);
  const { t } = useTranslation();
  const [currentRoute, setCurrentRoute] = useState('products');
  const [isDashboardAccessible, setIsDashboardAccessible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shiftType, setShiftType] = useState('all');
  const currentDate = new Date();
  currentDate.setHours(8, 0, 0, 0);
  const [from, setFrom] = useState(currentDate.getTime());
  const [timeRangeOptions, setTimeRangeOptions] = useState(timeRanges(t));
  const defaultTimeOptionsLen = timeRanges(t).length;
  const [range, setRange] = useState(timeRangeOptions[7].value);
  const dashboardUrl = `${config.grafanaConfig.eachProductDashboard}&var-product=${PRODUCT_ID}&${range}`;

  const productDetails = {
    PRODUCT_ID,
    currentRoute,
    product,
    setCurrentRoute,
  };

  useEffect(() => { 
    routesContext.setCurrentRoute('allProductsPenAssembly');
    checkDashboardAccess(dashboardUrl);

    const searchParams = new URLSearchParams(window.location.search);
    const fromUrlParam = searchParams.has('from') ? searchParams.get('from') * 1 : '';
    const toUrlParam = searchParams.has('to') ? searchParams.get('to') * 1 : '';
  
    if (fromUrlParam !== '' && toUrlParam !== '') {
      const fromTimestamp = fromUrlParam;
      const toTimestamp = toUrlParam;
  
      const newTimeRange = {
        id: defaultTimeOptionsLen+1,
        label: `${moment(fromTimestamp).format('DD/MM/YY HH:mm')}-${moment(toTimestamp).format('DD/MM/YY HH:mm')}`,
        value: `from=${fromTimestamp}&to=${toTimestamp}`,
      };
      setTimeRangeOptions(prevOptions => [...prevOptions, newTimeRange]);
    }
  }, []);

  useEffect(() => {
    if(timeRangeOptions.length > defaultTimeOptionsLen){
      setRange(timeRangeOptions[defaultTimeOptionsLen].value);
    } 
  }, [timeRangeOptions]);
  
  useEffect(() => { 
    if(shiftType !== 'all' && (timeRangeOptions.length > defaultTimeOptionsLen)){
      setTimeRangeOptions([...timeRanges(t)]);
    }
    useSetRange(shiftType, from, setFrom, setRange, timeRangeOptions, defaultTimeOptionsLen);
  }, [from, shiftType]);

  const checkDashboardAccess = async (dashboardUrl) => {
    const accessible = await grafanaUrlAccessible(`${config.grafanaConfig.reportBase}/${dashboardUrl}`);
    setIsDashboardAccessible(accessible);
    setIsLoading(false);
  };

  useEffect(() => {
    const eventHandler = (event) => iframeHandler(event, timeRangeOptions, defaultTimeOptionsLen, setTimeRangeOptions);
     
     window.addEventListener('message', eventHandler);
 
     // Clean up the event listener on component unmount
     return () => {
       window.removeEventListener('message', eventHandler);
     };
   }, [timeRangeOptions, defaultTimeOptionsLen, setTimeRangeOptions]);

  return (
    <ProductDetailsProvider value={productDetails}>
      <Header
        shiftType={shiftType}
        setShiftType={setShiftType}
        range={range}
        setRange={setRange}
        timeRanges={timeRangeOptions}
        from={from}
        setFrom={setFrom}
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.container}>
            {isLoading ? (
              <div style={{ textAlign: 'center', marginTop:50 }}>
                <Spin size="large" />
              </div>
            ) : isDashboardAccessible ? (
              <IframeWrapper iframeSrc={dashboardUrl} height={'660px'} />
            ) : (
              <Result status="404" subTitle={t('label_dashboard_error')} />
            )}
          </div>
        </Col>
      </Row>
    </ProductDetailsProvider>
  );
};

export default AtlasSingleProduct;
