/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Button, Typography, Input, Select } from 'antd';
import { withTranslation } from 'react-i18next';
const { Title } = Typography;
const { Option } = Select;

const initialValue = {
  index: 0,
  state: 'AND',
  key: 'deviceModel',
  operator: '=',
  value: '',
};

class SearchQueryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [initialValue],
    };
  }

  componentDidMount() {
    const { value } = this.props.itemData;
    if (value !== '') {
      this.setState({ dataSource: value });
    } else {
      this.setState({
        dataSource: [initialValue],
      });
    }
  }

  getColumns = columnArray => {
    const columns = [];
    const deleteAction = {
      title: '',
      dataIndex: 'action',
      fixed: 'right',
      key: 'action',
      render: (text, record, index) => {
        return (
          <Button
            key={index}
            shape="circle"
            type="danger"
            icon={<LegacyIcon type={'delete'} />}
            onClick={e => this.handleDeleteRow(e, index)}
          />
        );
      },
    };
    const columnArrayClone = [...columnArray];
    Object.values(columnArrayClone).map((columnData, c) => {
      if (columnData.hasOwnProperty('input')) {
        const column = {
          title: columnData.name,
          dataIndex: columnData.key,
          key: columnData.key,
          render: (name, row, index) => {
            return (
              <Input
                key={columnData.key}
                placeholder={columnData.input.placeholderValue}
                defaultValue={columnData.value}
                type={columnData.input.type}
                onBlur={value => {
                  this.setPayload(columnData.key, value, index, 'input');
                }}
              />
            );
          },
        };
        columns.push(column);
      } else if (columnData.hasOwnProperty('select')) {
        const options = columnData.select.options.map((item, index) => (
          <Option key={index} value={item.definedValue}>
            {item.displayingValue}
          </Option>
        ));
        const column = {
          title: columnData.name,
          dataIndex: columnData.key,
          key: columnData.key,
          render: (name, row, index) => {
            return (
              <Select
                key={columnData.key}
                defaultValue={columnData.value}
                onChange={value => {
                  this.setPayload(columnData.key, value, index, 'select');
                }}
              >
                {options}
              </Select>
            );
          },
        };
        columns.push(column);
      }
    });

    columns.push(deleteAction);

    return columns;
  };

  addRow = () => {
    const dataSource = [...this.state.dataSource];
    let itemKeyArray = [];
    dataSource.forEach(data => {
      itemKeyArray.push(data.index);
    });
    dataSource.push({
      index: Math.max(...itemKeyArray) + 1,
      state: 'AND',
      key: 'deviceModel',
      operator: '=',
      value: '',
    });
    this.setState({ dataSource });
  };

  handleDeleteRow = (e, index) => {
    const dataSource = [...this.state.dataSource];
    dataSource.splice(index, 1);
    this.setState({ dataSource });
  };

  clearParameters = () => {
    const { itemData } = this.props;
    const { value } = itemData;
    this.setState({ dataSource: value });
  };

  setPayload = (key, value, i, type) => {
    const { dataSource } = this.state;
    if (type === 'input') {
      dataSource[i][key] = value.target.value;
    } else {
      dataSource[i][key] = value;
    }
    this.props.callback(dataSource);
  };

  render() {
    const { itemData, t } = this.props;
    const { dataSource } = this.state;
    return (
      <div>
        {itemData.subTitle && <Title level={4}> {itemData.subTitle} </Title>}
        <div>
          <div style={{ marginBottom: 5 }}>
            <Button
              type="primary"
              onClick={() => this.addRow()}
              style={{ marginRight: 5 }}
            >
              {t('advanced_search_add_params')}
            </Button>
            <Button type="primary" onClick={() => this.clearParameters()}>
              {t('label_clearParameters')}
            </Button>
          </div>
          <Table
            dataSource={dataSource}
            columns={this.getColumns(itemData.table.columns, itemData)}
            rowKey="index"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SearchQueryTable);
