/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { PageHeader, Row, Col, Typography, DatePicker } from 'antd';
import styles from '../../../../styles.module.css';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import IframeWrapper from '../../../../../../components/IframeWrapper';
import { ReconciliationOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { RoutesContext } from '../../../../../../index';
import moment from 'moment';
import BreadcrumbComponent from '../../../../../../../../components/BreadCrumb';

const { Title } = Typography;
const { RangePicker } = DatePicker;
let start = '';
let end = '';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/analytics`,
      breadcrumbName: t('home_menu_analytics'),
    },
    {
      path: `/${appName}/analytics/device-connectivity`,
      breadcrumbName: t('label_deviceConnectivity'),
    },
    {
      path: `/${appName}/analytics/device-connectivity/signal-strength`,
      breadcrumbName: t('label_signalStrength'),
    },
    {
      breadcrumbName: t('label_signalStrengthByDevice'),
    },
  ];
};

class SignalStrengthByDevice extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    end = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        new Date().getMilliseconds(),
      ),
    );
    end = end.add(1, 'hours');
    start = moment().startOf('day');
    start = start.add(-1, 'weeks');
    this.state = {
      start: start,
      end: end,
      overtimePanelId: 7,
      selectedLanguage: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('signalStrengthByDevice');
  }

  onChangeDateRange = (dates, dateString) => {
    this.setState({
      start: dateString[0],
      end: dateString[1],
      isButtonEnable: true,
    });
    this.renderOvertimeCharts(dateString);
  };

  renderOvertimeCharts = dateString => {
    let chartId = 7;
    let difference = moment.duration(
      moment(dateString[1]).diff(moment(dateString[0])),
    );
    let differenceInDays = parseInt(difference.asDays(), 10);

    if (differenceInDays < 2) {
      chartId = 6;
    } else if (differenceInDays < 56) {
      chartId = 7;
    } else if (differenceInDays >= 56) {
      chartId = 2;
    }
    this.setState({ overtimePanelId: chartId });
  };

  render() {
    const { t } = this.props;
    const { overtimePanelId, selectedLanguage } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    const grafanaEmbedLinks = this.config.grafanaConfig.deviceConnectivity;
    const fromInMills = moment(this.state.start).unix() * 1000;
    const ToInMills = moment(this.state.end).unix() * 1000;
    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <ReconciliationOutlined />
                </span>
                {t('label_signalStrengthByDevice')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <div className={styles.container}>
            <div className={styles.controllerDiv}>
              <Row>
                <Col className={styles.col}>
                  <RangePicker
                    defaultValue={[this.state.start, this.state.end]}
                    showTime
                    onChange={this.onChangeDateRange}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.signalStrengthOverTimeByDevice +
                      '&panelId=' +
                      overtimePanelId +
                      '&var-device=' +
                      queryParams.get('deviceId') +
                      '&var-connectionType=' +
                      queryParams.get('connectionType') +
                      '&var-label_panelName' +
                      overtimePanelId +
                      '=' +
                      selectedLanguage
                    }
                    duration={`&from=${fromInMills}&to=${ToInMills}`}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

SignalStrengthByDevice.contextType = RoutesContext;
export default withConfigContext(withTranslation()(SignalStrengthByDevice));
