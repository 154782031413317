/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Spin, Select, Form, Checkbox, Col } from 'antd';
import TimeAgo from 'javascript-time-ago/modules/JavascriptTimeAgo';
import en from 'javascript-time-ago/locale/en';
import axios from 'axios';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import debounce from 'lodash.debounce';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6, offset: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
};

class IOSAgentQRForm extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    TimeAgo.addLocale(en);
    this.lastFetchId = 0;

    this.state = {
      data: [],
      value: [],
      fetching: false,
      isForbidden: false,
      userSelected: false,
      enrollmentModeSelected: false,
      enrollCurrentUserModeSelected: false,
      loading: false,
      showQRCode: false,
      payload: {},
    };
    this.fetchUser = debounce(this.fetchUser, 800);
  }

  fetchUser = value => {
    const config = this.props.context;
    const { t } = this.props;
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/users/search/usernames?filter=' +
          value +
          '&domain=all',
      )
      .then(res => {
        if (res.status === 200) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          const userData = res.data.data;
          const data = userData.map(user => ({
            text: user.username,
            value: user.username,
          }));
          this.setState({ data, fetching: false });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
        if (
          Object.prototype.hasOwnProperty.call(error, 'response') &&
          error.response.status === 403
        ) {
          this.setState({
            isForbidden: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
  };

  username = '';

  handleChange = value => {
    this.username = value.value;
    this.setState({
      value,
      data: [],
      fetching: false,
      userSelected: true,
    });
  };

  enrollmentMode = 'byod';

  onSelectEnrollmentMode = value => {
    this.enrollmentMode = value;
    this.setState({ enrollmentModeSelected: true });
  };

  getIOSQRPayload = () => {
    this.setState({ loading: true });
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/ios/v1.0/configuration/enrollment-qr-config/byod' +
      '?username=' +
      this.username +
      '&enrollment-mode=' +
      this.enrollmentMode;

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const url = res.data.data.payload;
          this.setState({
            loading: false,
            showQRCode: true,
            payload: url['web-agent-url'],
          });
          this.props.changeShowQR(url['web-agent-url']);
        }
      })
      .catch(error => {
        handleApiError(error, t('api_generateQRCodeError'), t);
        this.setState({
          loading: false,
        });
      });
  };

  onSelectUserMode = e => {
    let value;
    if (e.target.checked) {
      let username = this.config.user.split('@')[0];
      value = { value: username };
      this.username = username;
    } else {
      value = [];
    }
    this.setState({
      value,
      enrollCurrentUserModeSelected: e.target.checked,
    });
  };

  render() {
    const {
      fetching,
      data,
      value,
      userSelected,
      enrollmentModeSelected,
      enrollCurrentUserModeSelected,
      loading,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <Col span={16} offset={0} xs={24} sm={24} md={24} lg={24} xl={16}>
          <h3>
            {' '}
            {t('title_enrollAgent', {
              deviceType: t(`label_${this.props.deviceType}`),
            })}
          </h3>
          <div>
            <p> {t('enrollAgent_ios_infoTxt')}</p>
          </div>
          <div style={{ marginTop: 30, textAlign: 'center' }}>
            <div style={{ marginBottom: 30, marginLeft: 10 }}>
              <span style={{ marginRight: 10 }}>
                {t('label_enrollUnderLoggedInUser')} :
              </span>
              <Checkbox onChange={this.onSelectUserMode} />
            </div>
            <Form {...formItemLayout}>
              <Form.Item
                label={t('label_username')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  labelInValue
                  value={value}
                  placeholder={t('form_enterUsername')}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.fetchUser}
                  onChange={this.handleChange}
                  disabled={enrollCurrentUserModeSelected}
                >
                  {data.map(d => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('label_enrollmentMode')}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={'None'}
              >
                <Select
                  onSelect={this.onSelectEnrollmentMode}
                  placeholder={t('form_searchEnrolmentMode')}
                >
                  <Option key="AGENT_ENROLLMENT">
                    {t('label_enrollWithAgent')}
                  </Option>
                  <Option key="default">{t('label_enrollWithoutAgent')}</Option>
                </Select>
              </Form.Item>
            </Form>
            <Button
              loading={loading}
              disabled={
                !(
                  (userSelected || enrollCurrentUserModeSelected) &&
                  enrollmentModeSelected
                )
              }
              type={'primary'}
              style={{ marginTop: 10 }}
              onClick={this.getIOSQRPayload}
            >
              {t('label_generateEnrollQR')}
            </Button>
          </div>
        </Col>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(IOSAgentQRForm));
